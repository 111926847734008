/*
 * © 2024 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { call } from "redux-saga/effects";
import { fetchConnectionDetails } from "./saga";

export function* manageMyConnectorsRootSaga() {
  yield call(fetchConnectionDetails);
}
