/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import {
  OffsetAdjustmentAppliedAndCalculation,
  SortDirection,
} from "@centralwebteam/narwhal";
import {
  ProcessUpdatesPerfSortColumn,
  OffsetAdjustmentTableSortColumn,
  ProcessUpdatesDetailsSortColumn,
  ProcessUpdatePerf,
  ProcessUpdatesDetailsInnerTableSortColumn,
} from "@/store/state";
import { ProcessUpdatePresentation } from "@/presentation/processUpdates";
import { JobPresentation } from "@/presentation/Job";
export const processUpdatesActions = {
  fetchingStarted: createStandardAction("[process updates] fetching data")(),
  fetchingFinished: createStandardAction(
    "[process updates] finished fetching data"
  )<{
    to: string;
  }>(),
  offsetAdjustmentFetched: createStandardAction(
    "[process updates][saga] offset applied fetched"
  )<{
    events: OffsetAdjustmentAppliedAndCalculation[];
    from: string;
    to: string;
  }>(),
  offsetAdjustmentDeltaFetched: createStandardAction(
    "[process updates][saga] offset applied delta fetched"
  )<{
    events: OffsetAdjustmentAppliedAndCalculation[];
    from: string;
    to: string;
  }>(),
  setCalculatedProcessUpdates: createStandardAction(
    "[process updates][saga] set calculated process updates"
  )<{
    processUpdatesPerf: MappyAbsolute<ProcessUpdatePerf>;
    processUpdateDetails: MappyAbsolute<ProcessUpdatePresentation[]>;
    processUpdateDetailsMore: MappyAbsolute<
      MappyAbsolute<ProcessUpdatePresentation[]>
    >;
  }>(),
  perfHeaderCellClicked: createStandardAction(
    "[process updates] perf header cell clicked"
  )<{
    sortColumn: ProcessUpdatesPerfSortColumn;
    sortDirection: SortDirection;
  }>(),
  toolOffsetTypeRowClicked: createStandardAction(
    "[process updates] tool offset type row clicked"
  )<{ name: string }>(),
  expandAllClicked: createStandardAction(
    "[process updates] expand all clicked"
  )<string[]>(),
  collapseAllClicked: createStandardAction(
    "[process updates] collapse all clicked"
  )<void>(),
  offsetAdjustmentTableHeaderClicked: createStandardAction(
    "[process updates] offset adjustment table header clicked"
  )<[OffsetAdjustmentTableSortColumn, SortDirection]>(),
  detailsTableHeaderCellClicked: createStandardAction(
    "[process updates] details table header cell clicked"
  )<[ProcessUpdatesDetailsSortColumn, SortDirection]>(),
  detailsTableRowClicked: createStandardAction(
    "[process updates] details table row clicked"
  )<{ name: string }>(),
  detailsTableExpandAllClicked: createStandardAction(
    "[process updates] details table expand all clicked"
  )<string[]>(),
  detailsTableCollapseAllClicked: createStandardAction(
    "[process updates] details table collapse all clicked"
  )<void>(),
  detailsTableInnerTableHeaderCellClicked: createStandardAction(
    "[process updates] details table inner table header cell clicked"
  )<[ProcessUpdatesDetailsInnerTableSortColumn, SortDirection]>(),
  detailsTableInnerTableRowClicked: createStandardAction(
    "[process updates] details table inner table row clicked"
  )<{ processUpdate: ProcessUpdatePresentation }>(),
  detailsTableInnerTableRowClickedRedirect: createStandardAction(
    "[process updates] details table inner table row clicked redirect"
  )<{ job: JobPresentation; processUpdate: ProcessUpdatePresentation }>(),
  detailsTableInnerTableRowClickedSetJobDetails: createStandardAction(
    "[process updates] details table inner table row clicked set job details"
  )<{ job: JobPresentation }>(),
};

export type ProcessUpdatesActions = ActionType<typeof processUpdatesActions>;
