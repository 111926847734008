/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { cancellableAxios, CancellableAxiosRequest } from "@/axios/cancellable";
import {
  Alert,
  AuthResponse,
  CentralUser,
  Client,
  ClientPatch,
  CycleTime,
  EntityPermission,
  Event,
  GUID,
  Job,
  JobSummary,
  Licence,
  Location,
  LocationPatch,
  LocationPost,
  Machine,
  MachineConfiguration,
  MachinePatch,
  MachineStateSummary,
  MachineStatus,
  MachineType,
  MeasurementCharacteristic,
  MeasurementCharacteristicVerdict,
  NewAccount,
  ProcessAction,
  ProvisionMachine,
  QualitySummary,
  System,
  SystemPermissionsMap,
  TimeSeries,
  TimeSeriesLimit,
  TimeSeriesValue,
  TimeSeriesValueCompact,
  UniqueMeasurementType,
  UnitDisplayHint,
  IPCOffsetSettings,
  OffsetAdjustmentApplied,
  OffsetAdjustmentCalculation,
  ISOString,
  FileInfoForSelectedJob,
  FileDetailsForSelectedJob,
  MyNotification,
} from "@centralwebteam/narwhal";
import { createJobPresentation, JobPresentation } from "@/presentation/Job";
import {
  MeasurementCharacteristicPresentation,
  createMeasurementCharacteristicPresentation,
} from "@/presentation/MeasurementCharacteristic";
import {
  MeasurementCharacteristicVerdictPresentation,
  createMeasurementCharacteristicVerdictPresentation,
} from "@/presentation/MeasurementCharactericVerdict";

export type QueryOptions = {
  [key: string]: string | number | undefined | boolean;
  from?: string;
  to?: string;
  take?: number;
  skip?: number;
  machineId?: string;
  sensorId?: string;
  jobId?: string;
  timeSeriesId?: string;
  deleteChildren?: boolean;
  umrt?: string;
  lng?: string;
  sample?: "Raw" | "Hour" | "Day" | "Week" | "Month" | "Default";
};

export type CMSClientRequestOptions = {
  query?: QueryOptions | URLSearchParams;
  method?: "get" | "patch" | "put" | "post" | "delete";
  data?: any;
  headers?: any;
};

/**
 * CMSClient
 * This is a convenience module for typing the response of an axios request
 * This module uses the cancellableAxios module which returns:
 * promise - the passed promise
 * cancel - a function which when called cancels the promise
 */

export const CMSClient = {
  system: () =>
    cancellableAxios<System>({
      method: "get",
      url: "system",
    }),
  auth: {
    access: (params: {
      grant_type: string;
      client_id: string;
      username: string;
      password: string;
      scope: string;
    }) => {
      const formData = new URLSearchParams();
      // @ts-ignore
      Object.keys(params).forEach((key) => formData.append(key, params[key]));
      return cancellableAxios<AuthResponse>({
        method: "post",
        data: formData,
        url: "token",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    },
    refresh: (params: {
      grant_type: string;
      refresh_token: string | undefined;
      client_id: string;
    }) => {
      const formData = new URLSearchParams();
      // @ts-ignore
      Object.keys(params).forEach((key) => formData.append(key, params[key]));
      return cancellableAxios<AuthResponse>({
        method: "post",
        data: formData,
        url: "token",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
    },
  },
  locations: {
    query: (query: string) =>
      cancellableAxios<Location[]>({
        url: `locations/query?${query}`,
      }),
    all: (options?: CMSClientRequestOptions) =>
      cancellableAxios<Location[]>({
        url: "locations",
        params: options ? options.query : {},
      }),
    flat: (options?: CMSClientRequestOptions) =>
      cancellableAxios<Location[]>({
        url: "locations/flat",
        params: options ? options.query : {},
      }),
    byId: (id: string) =>
      cancellableAxios<Location | undefined>({
        url: `locations/${id}`,
      }),
    update: (location: LocationPatch) => {
      return cancellableAxios<void>({
        url: `locations/${location.id}`,
        method: "patch",
        data: removeRestrictedPatchKeys(location),
      });
    },
    add: (location: LocationPost) =>
      cancellableAxios<Location[]>({
        url: `locations`,
        method: "post",
        data: JSON.stringify([location]),
      }),
    delete: (locationId: string) =>
      cancellableAxios<void>({
        url: `locations/${locationId}`,
        method: "delete",
        params: {
          deleteChildren: true,
        },
      }),
  },
  clients: {
    all: (options?: CMSClientRequestOptions) =>
      cancellableAxios<Client[]>({
        url: "clients?loadPermissions=true",
        params: options ? options.query : {},
      }),
    byId: (id: string) =>
      cancellableAxios<Client | undefined>({
        url: `clients/${id}`,
      }),
    add: (client: ClientPatch) =>
      cancellableAxios<Client[]>({
        url: `clients`,
        method: "post",
        data: JSON.stringify([client]),
      }),
    update: (client: ClientPatch) =>
      cancellableAxios<void>({
        url: `clients/${client.id}/rename`,
        method: "patch",
        data: JSON.stringify(client.name),
      }),
    delete: (clientId: string) =>
      cancellableAxios<void>({
        url: `clients/${clientId}`,
        method: "delete",
      }),
    activate: (id: GUID) =>
      cancellableAxios({
        url: `clients/${id}/reactivate`,
        method: "patch",
      }),
    deactivate: (id: GUID) =>
      cancellableAxios({
        url: `clients/${id}/deactivate`,
        method: "patch",
      }),
    updatePermissions: (
      id: GUID,
      permissionsMap: Partial<SystemPermissionsMap>
    ) => {
      // Only send known properties – this object gets extended
      const {
        dataAdmin,
        fileCreator,
        locationCreator,
        machineCreator,
        tenantAdmin,
      } = permissionsMap;
      const data = {
        dataAdmin,
        fileCreator,
        locationCreator,
        machineCreator,
        tenantAdmin,
      };

      return cancellableAxios({
        url: `permissions/clients/${id}/system`,
        method: "patch",
        data,
      });
    },
    updateLocationPermissions: (
      id: GUID,
      locationEntityPermissions: EntityPermission[]
    ) =>
      cancellableAxios({
        url: `permissions/clients/${id}/locations`,
        method: "put",
        data: locationEntityPermissions,
      }),
    deleteLocationPermissions: (id: GUID, locationIds: string[]) =>
      cancellableAxios({
        url: `permissions/clients/${id}/locations`,
        method: "delete",
        data: locationIds,
      }),
    updateMachinePermissions: (
      id: GUID,
      machineEntityPermissions: { entityId: string; permission: string }[]
    ) =>
      cancellableAxios({
        url: `permissions/clients/${id}/machines`,
        method: "put",
        data: machineEntityPermissions,
      }),
    deleteMachinePermissions: (id: GUID, machineIds: string[]) =>
      cancellableAxios({
        url: `permissions/clients/${id}/machines`,
        method: "delete",
        data: machineIds,
      }),
    addClientScopes: (id: GUID, scopes: string[]) =>
      cancellableAxios<void>({
        url: `clients/${id}/scopes`,
        method: "post",
        data: JSON.stringify(scopes),
      }),
    deleteClientScope: (id: GUID, scopes: string[]) => {
      const search = new URLSearchParams();
      if (scopes && scopes.length)
        scopes.forEach((name) => search.append("scope", name));
      return cancellableAxios({
        url: `clients/${id}/scopes`,
        method: "delete",
        params: search,
      });
    },
    generateNewCredentials: (id: GUID) =>
      cancellableAxios({
        url: `clients/${id}/generateNewCredentials`,
        method: "post",
      }),
  },
  machines: {
    types: () =>
      cancellableAxios<string[]>({
        url: "machines/types",
      }),
    query: (query: string) =>
      cancellableAxios<Machine[]>({
        url: `machines/query?${query}`,
      }),
    all: (options?: CMSClientRequestOptions) =>
      cancellableAxios<Machine[]>({
        url: "machines",
        params: options ? options.query : {},
      }),
    byId: (id: string) =>
      cancellableAxios<Machine | undefined>({
        url: `machines/${id}`,
      }),
    add: (newMachine: MachinePatch) =>
      cancellableAxios({
        url: "machines/",
        method: "post",
        data: [newMachine],
      }),
    update: (machine: Partial<MachinePatch>) =>
      cancellableAxios<void>({
        url: `machines/${machine.id}`,
        method: "patch",
        data: JSON.stringify(removeRestrictedPatchKeys(machine)),
      }),
    delete: (machineId: string) =>
      cancellableAxios<void>({
        url: `machines/${machineId}`,
        method: "delete",
      }),
    linkMachine: (locationId: string, machineId: string) =>
      cancellableAxios<void>({
        url: `locations/${locationId}/linkmachines`,
        data: JSON.stringify([
          { created: new Date().toISOString(), machineId },
        ]),
        method: "post",
      }),
    licences: {
      getAllAssignedLicences: () =>
        cancellableAxios<Licence[]>({
          url: "/licences/assigned",
        }),
      getAllUnassignedLicences: () =>
        cancellableAxios<Licence[]>({
          url: "/licences/unassigned",
        }),
      assignLicenceToMachine: ({
        licenceId,
        machineId,
      }: {
        licenceId: string;
        machineId: string;
      }) =>
        cancellableAxios({
          url: `/licences/assign/${licenceId}`,
          method: "patch",
          data: { machineId },
        }),
      unassignLicence: (assignedLicenceId: string) =>
        cancellableAxios<void>({
          url: `/licences/unassign/${assignedLicenceId}`,
          method: "delete",
        }),
      getServerLicenceState: () =>
        cancellableAxios<string>({
          url: "/licences/state",
        }),
      getServerLicenseDaysRemaining: () =>
        cancellableAxios<number>({
          url: "/licences/daysremaining",
        }),
      refreshLicences: () =>
        cancellableAxios<void>({ url: "/licences/refresh", method: "post" }),
    },
    /**
     * Specify machine using machineId query parameter
     */
    stateSummary: ({
      from,
      to,
      machineIds,
    }: {
      from: string;
      to: string;
      machineIds: string[];
    }) => {
      const search = new URLSearchParams();
      machineIds.forEach((id) => search.append("machineId", id));
      search.append("from", from);
      search.append("to", to);
      return cancellableAxios<MachineStateSummary[]>({
        url: "events/machinestatus/summarystats",
        params: search,
      });
    },
    /**
     * Specify machine using machineId query parameter
     */
    cycleTime: ({
      from,
      to,
      machineIds,
      jobName,
    }: {
      from: string;
      to: string;
      machineIds: string[];
      jobName?: string;
    }) => {
      const search = new URLSearchParams();
      search.append("from", from);
      search.append("to", to);
      machineIds.forEach((id) => search.append("machineId", id));
      if (jobName) search.append("jobName", jobName);
      return cancellableAxios<CycleTime[]>({
        url: "jobs/cycletimesummarystats",
        params: search,
      });
    },
    /**
     * Specify machine using machineId query parameter
     */
    quality: ({
      from,
      to,
      machineIds,
    }: {
      from: string;
      to: string;
      machineIds: string[];
    }) => {
      const search = new URLSearchParams();
      machineIds.forEach((id) => search.append("machineId", id));
      search.append("from", from);
      search.append("to", to);
      return cancellableAxios<QualitySummary[]>({
        url: "jobs/qualitysummarystats",
        params: search,
      });
    },
  },
  machineProvisioning: {
    get: (options?: CMSClientRequestOptions) =>
      cancellableAxios<ProvisionMachine[]>({
        url: "provisioning/machines",
        params: options ? options.query : {},
      }),
    approve: (
      registrationId: string,
      data: {
        machine: {
          id?: string;
          name?: string;
          make?: string;
          model?: string;
          serialNumber?: string;
          type?: MachineType;
        };
        locationId?: string;
      }
    ) =>
      cancellableAxios<void>({
        url: `provisioning/machines/approval/${registrationId}`,
        method: "post",
        data: JSON.stringify(data),
      }),
    delete: (registrationId: string) =>
      cancellableAxios<void>({
        url: `provisioning/machines/${registrationId}`,
        method: "delete",
      }),
  },
  events: {
    query: <T extends Event[]>(query: string) =>
      cancellableAxios<T>({
        url: `events/query?${query}`,
      }),
    byId: <T extends Event[]>(id: string) =>
      cancellableAxios<T>({
        url: `events?id=${id}`,
      }),
    add: (machineId: GUID, data: any) => {
      return cancellableAxios({
        method: "post",
        url: `events/${machineId}`,
        data: JSON.stringify(data),
      });
    },
    measurementCharacteristics: {
      all: ({
        jobName,
        featureName,
        name,
        ...rest
      }: {
        from: string;
        to: string;
        machineId: string;
        jobName?: string[];
        featureName?: string;
        name?: string;
        take?: number;
      }) => {
        const search = new URLSearchParams(rest as any);
        if (featureName) search.append("featureName", featureName);
        if (name) search.append("name", name);
        if (jobName) jobName.forEach((name) => search.append("jobName", name));
        return mapMeasurementCharactersticsToMeasurementCharacteristicPresentation(
          cancellableAxios<MeasurementCharacteristic[]>({
            url: "events/measurementcharacteristics",
            params: search,
          })
        );
      },
      unique: ({
        from,
        to,
        machineId,
        jobs,
        take,
      }: {
        from: string;
        to: string;
        machineId: string;
        jobs?: string[];
        take?: number;
      }) => {
        const search = new URLSearchParams();
        if (jobs && jobs.length)
          jobs.forEach((name) => search.append("jobName", name));
        search.append("from", from);
        search.append("machineId", machineId);
        search.append("to", to);
        if (take) search.append("take", take.toString());
        return cancellableAxios<UniqueMeasurementType[]>({
          url: "events/measurementcharacteristics/unique/",
          params: search,
        });
      },
      verdicts: (options?: CMSClientRequestOptions) =>
        mapMeasurementVerdictToPresentation(
          cancellableAxios<MeasurementCharacteristicVerdict[]>({
            url: "events/measurementcharacteristics/verdicts/",
            params: options?.query,
          })
        ),
    },
    alerts: {
      all: (options?: CMSClientRequestOptions) =>
        cancellableAxios<Alert[]>({
          url: "events",
          params: { ...options?.query, type: "Alert" },
        }),
      byId: (id: string) =>
        cancellableAxios<Alert | undefined>({
          url: `events/${id}`,
        }),
      /** Gets alerts with a level of Error and active true */
      errors: (from: ISOString, to: ISOString, machineId: GUID, take: number) =>
        cancellableAxios<Alert[]>({
          url: `events/query?$filter=type eq 'Alert' and level eq 'Error' and active eq 'true' and machineId eq ${machineId} and created gt ${from} and created lt ${to} &$orderby=created desc &$top=${take}`,
        }),
    },
    jobProperties: {
      displayHintUnits: (options?: CMSClientRequestOptions) =>
        cancellableAxios<UnitDisplayHint[]>({
          url: "events",
          params: {
            ...options?.query,
            type: "JobProperty",
            subType: "DisplayHintUnits",
          },
        }),
      displayHintUnitsLatest: (
        from: ISOString,
        to: ISOString,
        machineId: string
      ) =>
        cancellableAxios<UnitDisplayHint[]>({
          url: `events/query?$filter=type eq 'JobProperty' and subtype eq 'DisplayHintUnits' and machineId eq ${machineId} and created gt ${from} and created lt ${to} &$orderby=created desc &$top=1`,
        }),
    },
    processActions: {
      all: (options?: CMSClientRequestOptions) =>
        cancellableAxios<ProcessAction[]>({
          url: "events",
          params: { ...options?.query, type: "ProcessAction" },
        }),
    },
    masteringUpdates: {
      all: (options?: CMSClientRequestOptions) =>
        cancellableAxios<ProcessAction[]>({
          url: "events",
          params: {
            ...options?.query,
            type: "ProcessAction",
            subType: "MasteringUpdate",
          },
        }),
    },
    ipcOffsetSettings: {
      all: (options?: CMSClientRequestOptions) =>
        cancellableAxios<IPCOffsetSettings[]>({
          url: "events",
          params: {
            ...options?.query,
            type: "Configuration",
            subType: "IPCOffsetSettings",
          },
        }),
    },
    offsetAdjustmentApplied: {
      all: (from: ISOString, to: ISOString, take: number) =>
        cancellableAxios<OffsetAdjustmentApplied[]>({
          url: `events/query?$filter=type eq 'ProcessAction' and subtype eq 'OffsetAdjustmentApplied' and created gt ${from} and created lt ${to} &$orderby=created desc &$top=${take}`,
        }),
    },
    offsetAdjustmentCalculation: {
      all: (from: ISOString, to: ISOString, take: number) =>
        cancellableAxios<OffsetAdjustmentCalculation[]>({
          url: `events/query?$filter=type eq 'ProcessAction' and subtype eq 'OffsetAdjustmentCalculation' and created gt ${from} and created lt ${to} &$orderby=created desc &$top=${take}`,
        }),
    },
    machinestatus: {
      all: (options?: CMSClientRequestOptions) =>
        cancellableAxios<MachineStatus[]>({
          url: `events/machinestatus`,
          params: options ? options.query : {},
        }),
    },
    statusUpdates: {
      all: (machineIds: GUID[], options: CMSClientRequestOptions) => {
        let params: URLSearchParams;
        // We may have a QueryOptions here (a plain object with some fields we've defined) or a URLSearchParams (built-in which accepts multiples of the same query string)
        if (options.query?.entries) {
          params = options.query as URLSearchParams;
        } else {
          const queryOptions = options.query as QueryOptions;
          params = new URLSearchParams();
          for (const o in queryOptions) {
            if (o) params.append(o, queryOptions[o]!.toString());
          }
          for (const m of machineIds) {
            if (m) params.append("machineId", m);
          }
          params.append("type", "MachineStatus");
        }

        return cancellableAxios<MachineStatus[]>({
          url: "events",
          params,
        });
      },
    },
    machineConfiguration: {
      byMachineId: (machineId: GUID) =>
        cancellableAxios<MachineConfiguration[]>({
          url: `events/query?$filter=type eq 'MachineConfiguration' and subtype eq 'MachineCommunicationSettings' and machineId eq ${machineId}&$orderby=created desc&$top=1`,
        }),
      update: (machineId: GUID, config: MachineConfiguration) => {
        delete config.machineId;
        return cancellableAxios({
          method: "post",
          url: `events/${machineId}`,
          data: [config],
        });
      },
    },
  },
  timeSeries: {
    query: (query: string) =>
      cancellableAxios<TimeSeries[]>({
        url: `timeseries/query?${query}`,
      }),
    all: (options?: CMSClientRequestOptions) =>
      cancellableAxios<TimeSeries[]>({
        url: "timeseries",
        params: options ? options.query : {},
      }),
    active: (options?: CMSClientRequestOptions) =>
      cancellableAxios<TimeSeries[]>({
        url: "timeseries/active/",
        params: options ? options.query : {},
      }),
    values: (options?: CMSClientRequestOptions) =>
      cancellableAxios<TimeSeriesValue[]>({
        url: "timeseriesvalues/",
        params: options ? options.query : {},
      }),
    compactValues: (options?: CMSClientRequestOptions) => {
      const { promise, cancel } = cancellableAxios<TimeSeriesValueCompact>({
        url: "timeseriesvalues/compact",
        params: options ? options.query : {},
      });
      return {
        promise: promise.then((res) =>
          // map from the awkward array object to record type
          res.reduce(
            (obj, next) => ({
              ...obj,
              [Object.keys(next)[0]]: Object.values(next)[0],
            }),
            {}
          )
        ),
        cancel,
      };
    },
    limits: (options?: CMSClientRequestOptions) =>
      cancellableAxios<TimeSeriesLimit[]>({
        url: "timeserieslimits",
        params: options ? options.query : {},
      }),
  },
  jobs: {
    query: (query: string) =>
      mapJobsToJobPresentation(
        cancellableAxios<Job[]>({
          url: `jobs/query?${query}`,
        })
      ),
    all: (options?: CMSClientRequestOptions) =>
      mapJobsToJobPresentation(
        cancellableAxios<Job[]>({
          url: "jobs",
          params: options ? options.query : {},
        })
      ),
    byId: (id: string) =>
      mapJobToJobPresentation(
        cancellableAxios<Job>({
          url: `jobs/${id}`,
        })
      ),
    summary: (options?: CMSClientRequestOptions) =>
      mapJobSummariesToJobPresentation(
        cancellableAxios<Mappy<JobSummary[]>>({
          url: "jobs/summaries",
          params: options ? options.query : {},
        })
      ),
    summaryExtended: (options?: CMSClientRequestOptions) =>
      cancellableAxios<MappyAbsolute<JobSummary[]>>({
        url: `/jobs/summariesextended`,
        params: options ? options.query : {},
      }),
    /**
     * TODO: Can we remove this yet?
     * Temp only - Doesn't transform the new grouped format to flat array and allows multiple machine ids to be passed for filtering.
     */
    tempRawSummary: ({
      machines,
      jobs,
      from,
      to,
      take,
    }: {
      machines?: string[];
      jobs?: string[];
      from: string;
      to: string;
      take?: number;
    }) => {
      const search = new URLSearchParams();
      if (machines && machines.length)
        machines.forEach((id) => search.append("machineId", id));
      if (jobs && jobs.length) jobs.forEach((id) => search.append("jobId", id));
      search.append("from", from);
      search.append("to", to);
      if (take) search.append("take", take.toString());
      return cancellableAxios<Mappy<JobSummary[]>>({
        url: `jobs/summaries`,
        params: search,
      });
    },
    summaryQuery: (query: string) =>
      mapJobSummariesToJobPresentation(
        cancellableAxios<Mappy<JobSummary[]>>({
          url: `jobs/summaries/query?${query}`,
        })
      ),
  },
  myNotifications: {
    getSettings: () =>
      cancellableAxios<any>({
        url: "/preferences/notifications/settings",
      }),
    activateSettings: (type: string) =>
      cancellableAxios({
        url: `/preferences/notifications/${type}/activate`,
        method: "put",
      }),
    deactivateSettings: (type: string) =>
      cancellableAxios({
        url: `/preferences/notifications/${type}/deactivate`,
        method: "put",
      }),
    all: () =>
      cancellableAxios<MyNotification[]>({
        method: "get",
        url: "/preferences/notifications",
      }),
    update: (notifications: MyNotification[]) =>
      cancellableAxios<void>({
        url: `/preferences/notifications`,
        method: "put",
        data: JSON.stringify(notifications),
      }),
    delete: (machineIds: string[]) => {
      return cancellableAxios({
        url: `/preferences/notifications?machineId=${machineIds.join("&machineId=")}`,
        method: "delete",
      });
    },
  },
  users: {
    all: () =>
      cancellableAxios<CentralUser[]>({
        url: "centralusers?loadPermissions=true",
      }),
    byId: (id: GUID) =>
      cancellableAxios<CentralUser | undefined>({
        url: `centralusers/${id}`,
      }),
    add: (newAccount: NewAccount) =>
      cancellableAxios({
        url: "centralusers/",
        method: "post",
        data: [
          {
            ...newAccount,
            locationEntityPermissions:
              newAccount.locationEntityPermissions?.filter((ep) =>
                // You can't set a permission to "None" or "Inherit"
                ["Admin", "Read"].includes(ep.permission)
              ),
          },
        ],
      }),
    delete: (id: GUID) =>
      cancellableAxios({
        url: `centralusers/${id}`,
        method: "delete",
      }),
    updatePermissions: (id: GUID, permissionsMap: SystemPermissionsMap) =>
      cancellableAxios({
        url: `permissions/centralusers/${id}/system`,
        method: "patch",
        data: permissionsMap,
      }),
    updateLocationPermissions: (
      id: GUID,
      locationEntityPermissions: EntityPermission[]
    ) =>
      cancellableAxios({
        url: `permissions/centralusers/${id}/locations`,
        method: "put",
        data: locationEntityPermissions.filter((ep) =>
          // You can't set a permission to "None" or "Inherit"
          ["Admin", "Read"].includes(ep.permission)
        ),
      }),
    deleteLocationPermissions: (id: GUID, locationIds: string[]) =>
      cancellableAxios({
        url: `permissions/centralusers/${id}/locations`,
        method: "delete",
        data: locationIds,
      }),
    activate: (id: GUID) =>
      cancellableAxios({
        url: `centralusers/${id}/reactivate`,
        method: "patch",
      }),
    deactivate: (id: GUID) =>
      cancellableAxios({
        url: `centralusers/${id}/deactivate`,
        method: "patch",
      }),
    resetPasswordSendLink: (email: string) =>
      cancellableAxios({
        url: `centralusers/password/reset`,
        method: "post",
        data: JSON.stringify(email),
      }),
    resetPasswordUpdate: (resetPassword: {
      newPassword: string;
      resetToken: string;
    }) =>
      cancellableAxios({
        url: `centralusers/password/reset`,
        method: "patch",
        data: resetPassword,
      }),
    changePassword: (passwords: {
      currentPassword: string;
      newPassword: string;
    }) =>
      cancellableAxios<Machine | undefined>({
        url: `centralusers/password/change`,
        method: "patch",
        data: passwords,
      }),
    changeEmail: (userDetails: {
      currentPassword: string;
      newEmailAddress: string;
    }) =>
      cancellableAxios({
        url: `centralusers/details`,
        method: "patch",
        data: userDetails,
      }),
  },
  preferences: {
    all: () =>
      cancellableAxios<any>({
        url: "preferences/application?application=webapp",
      }),
    updatePages: (preferences: any) =>
      cancellableAxios({
        url: `preferences/application?application=webapp`,
        method: "put",
        data: JSON.stringify(preferences),
      }),
  },
  provisioning: {
    get: () =>
      cancellableAxios<string>({
        method: "get",
        url: "/provisioning",
      }),
  },
  files: {
    byJobId: (jobId: string) =>
      cancellableAxios<FileInfoForSelectedJob[]>({
        method: "get",
        url: `files/links/events?id=${jobId}`,
      }),
    filesDetailsByFileInfoId: (fileInfoId: string) =>
      cancellableAxios<FileDetailsForSelectedJob[]>({
        method: "get",
        url: `files?id=${fileInfoId}`,
      }),
    downloadFileByFileInfoId: (fileInfoId: string) =>
      cancellableAxios<any>({
        method: "get",
        url: `/files/${fileInfoId}/download`,
        responseType: "blob",
      }),
  },
  myConnectors: {
    get: () =>
      cancellableAxios<any>({
        method: "get",
        url: "/files/query?$filter=classification eq 'CentralConnector' and subClassification eq 'MachineConnector'",
      }),
  },
};

export type CMSClientType = typeof CMSClient;

function mapJobsToJobPresentation(
  cancellableAxios: CancellableAxiosRequest<Job[]>
): CancellableAxiosRequest<JobPresentation[]> {
  const { promise, cancel } = cancellableAxios;
  return {
    promise: promise.then((res) =>
      res.map((x) =>
        createJobPresentation(
          x,
          mapJobEventsToMeasurementCharacteristicPresentation(x.events)
        )
      )
    ),
    cancel,
  };
}

function mapJobToJobPresentation(
  cancellableAxios: CancellableAxiosRequest<Job>
): CancellableAxiosRequest<JobPresentation> {
  const { promise, cancel } = cancellableAxios;
  return {
    promise: promise.then((x) =>
      createJobPresentation(
        x,
        mapJobEventsToMeasurementCharacteristicPresentation(x.events)
      )
    ),
    cancel,
  };
}

function mapJobSummariesToJobPresentation(
  cancellableAxios: CancellableAxiosRequest<Mappy<JobSummary[]>>
): CancellableAxiosRequest<JobPresentation[]> {
  const { promise, cancel } = cancellableAxios;
  return {
    promise: promise.then((res) => {
      const result: JobPresentation[] = [];
      for (const m in res) {
        result.push(...res[m]!.map((x) => createJobPresentation(x)));
      }
      return result;
    }),
    cancel,
  };
}

function mapMeasurementVerdictToPresentation(
  cancellableAxios: CancellableAxiosRequest<MeasurementCharacteristicVerdict[]>
): CancellableAxiosRequest<MeasurementCharacteristicVerdictPresentation[]> {
  const { promise, cancel } = cancellableAxios;
  return {
    promise: promise.then((res) =>
      res.map((x) => createMeasurementCharacteristicVerdictPresentation(x))
    ),
    cancel,
  };
}

function mapJobEventsToMeasurementCharacteristicPresentation(
  events?: Event[]
): MeasurementCharacteristicPresentation[] {
  if (!events) return [];
  const measurementEvents = events.filter(
    (event) => event.type === "MeasurementCharacteristic"
  ) as MeasurementCharacteristic[];
  return (
    measurementEvents.map(createMeasurementCharacteristicPresentation) ?? []
  );
}

function mapMeasurementCharactersticsToMeasurementCharacteristicPresentation(
  cancellableAxios: CancellableAxiosRequest<MeasurementCharacteristic[]>
): CancellableAxiosRequest<MeasurementCharacteristicPresentation[]> {
  const { promise, cancel } = cancellableAxios;
  return {
    promise: promise.then((res) =>
      res.map((x) => createMeasurementCharacteristicPresentation(x))
    ),
    cancel,
  };
}

/** Remove id and other keys that should not be sent in a PATCH.
 * This is used both on machines and locations. */
function removeRestrictedPatchKeys<
  T extends { [key: string]: any; id?: string },
>(o: T) {
  const readOnlyKeys = ["id", "clockSkew"];
  return Object.keys(o)
    .filter((key) => !readOnlyKeys.includes(key))
    .reduce((rO, k) => ({ ...rO, [k]: o[k] }), {} as { [key: string]: any });
}
