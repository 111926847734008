/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from "reselect";
import {
  subDays,
  startOfDay,
  subHours,
  differenceInCalendarDays,
  differenceInMilliseconds,
} from "date-fns";
import { scaleTime, scaleLinear } from "d3-scale";
import { extent } from "d3-array";
import { zoomIdentity } from "d3-zoom";
import { xor, groupBy, orderBy, sortBy } from "lodash/fp";
import {
  Machine,
  Licence,
  TimeSeriesValueCompactValue,
  MachineType,
  getMachineTypeAsString,
  OffsetAdjustmentApplied,
  MasteringUpdate,
  OffsetChangeDetected,
  units,
  MachineConfiguration,
  OffsetAdjustmentCalculation,
  DefaultTimeSeriesPrecision,
  GUID,
  UnitDisplayHintOverride,
  TimeSeriesLimit,
} from "@centralwebteam/narwhal";
import { assertUnreachable } from "@/@types/assertUnreachable";
import { notUndefined } from "@/@types/guards/notUndefined";
import { RootState } from ".";
import { initialFilterState } from "./reducer";
import { mapRouteNameToPath, Routes } from "./session/routeDefinitions";
import { isIsoDate } from "@/modules/string";
import { sortWorstToBest } from "@/presentation/MeasurementCharacteristic/sortWorstToBest";
import { Verdict, JobPresentation } from "@/presentation/Job";
import {
  KnownStartValues,
  knownStartValues,
  RefreshMode,
  AlertTypeReason,
  dataFetchedType,
  DataFetchedType,
  FocusedOrLatestJobViewMeasurementType,
} from "./state";
import { AlertLevel } from "@/presentation/Alert/level";
import { AlertState } from "@/presentation/Alert/state";
import {
  createUniqueMeasurementID,
  MeasurementCharacteristicPresentation,
} from "@/presentation/MeasurementCharacteristic";
import { Series } from "@/components/SeriesList";
import { calculateUptime } from "@/modules/mtbf";
import {
  fixedTimespanFormat,
  nextMillisecondISOString,
} from "@/modules/dateFormats";
import { t } from "@/modules/string/translate";
import { ProcessActionPresentation } from "../presentation/ProcessAction/index";
import { tidyNumber } from "@/modules/tidyNumber";
import constants from "@/constants";
import { removeUndefinedProps } from "@/modules/removeUndefinedProps";
import memo from "memoize-one";
import { SeriesCompactValuePresentation } from "@/presentation/SeriesCompactValuePresentation";
import { emptyStateText } from "@centralwebteam/jellyfish";

type hasID = { id: string };

/** Creates a memoized filter function. */
export function getFilteredResourceByKey(key: string) {
  return memo(function <T extends { [key: string]: any }>(
    resource: T[],
    filters: string[]
  ): T[] {
    if (!filters || !filters.length) return resource;
    return resource.filter((r) => {
      return filters.some((filter) => r[key] === filter);
    });
  }) as <T>(resource: T[], filters: string[]) => T[];
}

/**
 * A SelectorCreator for better caching of arrays of objects with IDs.
 * In place of the usual basic equality comparison, we check that the IDs of the objects in arrays a and b match.
 */
const createArrayIDsEqualSelector = createSelectorCreator(
  defaultMemoize,
  (a: hasID[], b: hasID[]) =>
    a.length === b.length &&
    a.every((ax) => b.some((bx) => bx.id === ax.id)) &&
    b.every((bx) => a.some((ax) => ax.id === bx.id))
);

// Selects all locations. Copied from the main selectors file because Webpack has problems with the import
export const selectAllLocations = createSelector(
  [(state: RootState) => state.global.locations],
  (locations) => {
    if (!locations?.length) return [];
    const ids = locations.map((l) => l.id);
    return locations.map((l) =>
      l.parentId && ids.includes(l.parentId) ? l : { ...l, parentId: undefined }
    );
  }
);

export const selectMachines = (state: RootState) => state.global.machines;

/** Selects all Machines, stripped of their frequently-changing properties such as secondsSinceLastContact.
 * Returned Machines have only these properties:
        id,
        name,
        type,
        make,
        model,
        serialNumber,
        isLicensed,
        ipAddress,
        locationId.
*/
export const selectMachinesInvariant = createArrayIDsEqualSelector(
  [selectMachines],
  (machines: Machine[]) =>
    machines.map((m) => {
      const {
        id,
        name,
        type,
        make,
        model,
        serialNumber,
        isLicensed,
        ipAddress,
        locationId,
      } = m;
      return {
        id,
        name,
        type,
        make,
        model,
        serialNumber,
        isLicensed,
        ipAddress,
        locationId,
      } as Machine;
    })
);

/** Selects all locations. See also store/filter/selectors for the filtered version.
 * The raw location list may contain locations that are not reachable from the root node, because parent locations are not visible to the current user.
 * This selector places locations without accessible parents at the root level by setting their parentId to undefined.
 */
export const selectLocations = createSelector(
  [(state: RootState) => state.global.locations],
  (locations) => {
    if (!locations?.length) return [];
    const ids = locations.map((l) => l.id);
    return locations.map((l) =>
      l.parentId && ids.includes(l.parentId) ? l : { ...l, parentId: undefined }
    );
  }
);

export const selectIsAuthenticated = (state: RootState) => state.authenticated;

/** Returns true if we are currently checking either the server licence or the user credentials */
export const selectIsAuthenticating = (state: RootState) =>
  state.authenticating;

export const selectUsername = (state: RootState) => state.username;

export const selectUserSystemPermissions = (state: RootState) =>
  state.systemPermissions;

export const selectPath = createSelector(
  (state: RootState) => state.view,
  (state: RootState) => state.params as any,
  (current, params): string => {
    return `${mapRouteNameToPath[current as Routes].stringify()}?${
      // only display the known params (by key) and those which have values
      Object.keys(params)
        .filter((key) => params[key] !== null && params[key] !== "")
        .map((key) => `${key}=${params[key]}`)
        .join("&")
    }`;
  }
);

export const selectCurrentRoute = (state: RootState) => state.view;

/**
 * This will return raw values so end is possibly undefined and start a token.
 */
export const selectStartAndEndRaw = ({ params: { start, end } }: RootState) =>
  [start, end] as const;

/**
 * IMPORTANT - This will always return actual Dates (as ISOString) and never the start token!
 */
export const selectStartAndEnd = ({ params: { start, end } }: RootState) => [
  isValidStartToken(start) ? getStartDate(start).toISOString() : start,
  end ?? new Date().toISOString(),
];

/**
 * IMPORTANT - This will always return actual Dates and never the start token!
 */
export const selectStartAndEndDates = createSelector(
  (state: RootState) => state.params.start,
  (state: RootState) => state.params.end,
  (start, end): [Date, Date] =>
    isValidStartToken(start)
      ? [getStartDate(start), new Date()]
      : dates(start, end)
);

const dates = (start: string, end: string | null): [Date, Date] => [
  new Date(start),
  end ? new Date(end) : new Date(),
];

export const selectFocusedMachine = createSelector(
  (state: RootState) => state.global.machines,
  (state: RootState) => state.params.focusedMachineId,
  (machines, focusedMachineId): Machine | undefined =>
    focusedMachineId
      ? machines.find(({ id }) => id === focusedMachineId)
      : undefined
);
export const selectFocusedMachineStateSummary = (s: RootState) =>
  s.focusedMachine.stateSummary;

export const selectFocusedJobId = (state: RootState) =>
  state.params.focusedJobId;

export const selectIsRollingMode = createSelector(
  (state: RootState) => state.params.start,
  (start): boolean => {
    return isValidStartToken(start);
  }
);

/**
 * Could be a string token and not a date string!
 */
export const selectRawStart = (state: RootState) => state.params.start;

export const selectPreviousLiveModeToken = (state: RootState) =>
  state.previousLiveModeToken;

/**
 * Gets the start date for live mode.
 */
export function getStartDate(token: KnownStartValues) {
  const now = new Date();
  switch (token) {
    case "1 hour": {
      return subHours(now, 1);
    }
    case "1 day": {
      return startOfDay(subDays(now, 1));
    }
    case "3 days": {
      return startOfDay(subDays(now, 3));
    }
    case "7 days": {
      return startOfDay(subDays(now, 7));
    }
    default: {
      assertUnreachable(token);
    }
  }
}

export function isValidStartToken(token: string): token is KnownStartValues {
  return knownStartValues.includes(token as KnownStartValues);
}
export const selectPasswordResetToken = createSelector(
  (state: RootState) => state.params.token,
  (token) => (!token || token === "" ? undefined : token)
);

const validateStartValue = (start: string | null) =>
  start === null
    ? "invalid"
    : isIsoDate(start)
      ? "date iso string"
      : isValidStartToken(start)
        ? "start token"
        : "invalid";
const validateEndValue = (end: string | null) =>
  end === null ? "invalid" : isIsoDate(end) ? "date iso string" : "invalid";

/**
 * Correctly handles many different start/end value combinations and returns either [token, null] (live mode) or [date string, date string] (sticky mode)
 * @param start
 * @param end
 */
export const getValidStartAndEndValues = (
  start: string | null,
  end: string | null
): [KnownStartValues, null] | [string, string] => {
  const startValueType = validateStartValue(start);
  const endValueType = validateEndValue(end);
  return startValueType === "date iso string" &&
    endValueType === "date iso string"
    ? [start! < end! ? start! : end!, end! > start! ? end! : start!]
    : startValueType === "date iso string" && endValueType === "invalid"
      ? [start!, new Date().toISOString()]
      : startValueType === "invalid" && endValueType === "date iso string"
        ? [subDays(new Date(end!), 1).toISOString(), end!]
        : startValueType === "start token"
          ? [start as KnownStartValues, null]
          : [knownStartValues[0], null];
};

export const getValidStartValue = (
  start: string,
  end: string | null
): string => {
  const daysDifference = daysDifferenceFromNow(start);
  if (end === null) {
    if (daysDifference <= 1) {
      return "1 day";
    } else if (daysDifference > 1 && daysDifference <= 3) {
      return "3 days";
    } else {
      return "7 days";
    }
  } else if (end !== null && daysDifference <= 1) {
    return "1 day";
  } else {
    return startOfDay(new Date(start)).toString();
  }
};

const daysDifferenceFromNow = (start: string): number => {
  const now = new Date();
  return differenceInCalendarDays(now, new Date(start));
};

export const selectMachineSelectorState = (s: RootState) => s.machineSelector;

export const selectFocusedMachineJobTypeFilter = (s: RootState) =>
  s.focusedMachine.filters.jobType;

export const selectFocusedMachineSelectedAlerts = (s: RootState) =>
  s.focusedMachine.selectedAlerts;

export const selectFocusedMachineParetoSelectedReason = (s: RootState) =>
  s.focusedMachine.alertTypeParetoSelectedReason;

export const selectFocusedMachineJobs = createSelector(
  [(s: RootState) => s.focusedMachine.jobs],
  (jobs: Mappy<JobPresentation>) =>
    Object.values(jobs).filter(notUndefined) as JobPresentation[]
);

const passVerdicts: Verdict[] = ["Pass", "Warning"];
const failVerdicts: Verdict[] = ["Fail"];

export type JobTypeSummary = {
  name: string;
  passes: number;
  fails: number;
  noVerdicts: number;
  completed: number;
  incomplete: number;
};
export const selectJobTypeSummaries = createSelector(
  [selectFocusedMachineJobs],
  (jobs): JobTypeSummary[] =>
    Object.values(
      jobs.reduce<{
        [name: string]: JobTypeSummary;
      }>((summaries, job) => {
        // figure out if we have an existing record
        const existingRecord = summaries[job.name];
        const verdictType = passVerdicts.includes(job.verdict)
          ? "pass"
          : failVerdicts.includes(job.verdict)
            ? "fail"
            : "no verdict";
        return existingRecord
          ? {
              ...summaries,
              [job.name]: {
                ...existingRecord,
                // record exists so increment
                passes:
                  existingRecord.passes + (verdictType === "pass" ? 1 : 0),
                fails: existingRecord.fails + (verdictType === "fail" ? 1 : 0),
                noVerdicts:
                  existingRecord.noVerdicts +
                  (verdictType === "no verdict" ? 1 : 0),
                completed:
                  existingRecord.completed +
                  (job.status.toLowerCase() === "completed" ? 1 : 0),
                incomplete:
                  existingRecord.incomplete +
                  (job.status.toLowerCase() !== "completed" ? 1 : 0),
              },
            }
          : {
              ...summaries,
              // create new record
              [job.name]: {
                name: job.name,
                passes: verdictType === "pass" ? 1 : 0,
                fails: verdictType === "fail" ? 1 : 0,
                noVerdicts: verdictType === "no verdict" ? 1 : 0,
                completed: job.status.toLowerCase() === "completed" ? 1 : 0,
                incomplete: job.status.toLowerCase() !== "completed" ? 1 : 0,
              },
            };
      }, {})
    )
);

export const selectActiveMeasurementDetails = createSelector(
  [(s: RootState) => s.focusedMachine.measurementDetails],
  (measurements) =>
    Object.values(measurements)
      .filter(notUndefined)
      .filter((measurement) => measurement.active)
);

export const selectActiveMeasurementDetailsWithoutData = createSelector(
  [
    selectActiveMeasurementDetails,
    (s: RootState) => s.focusedMachine.measurementValues,
  ],
  (selectActiveMeasurementTypes, values) =>
    selectActiveMeasurementTypes.filter(
      (type) => values[createUniqueMeasurementID(type)] === undefined
    )
);

export const selectStates = createSelector(
  [(s: RootState) => s.focusedMachine.states],
  (states) =>
    Object.values(states)
      .filter(notUndefined)
      .sort((a, b) => a.created.localeCompare(b.created))
);

export const selectAlerts = createSelector(
  [(s: RootState) => s.focusedMachine.alerts],
  (alerts) =>
    Object.values(alerts)
      .filter(notUndefined)
      .filter((a) => a.state === AlertState.set)
);

export const selectProcessActions = createSelector(
  [(s: RootState) => s.focusedMachine.processActions],
  (pa) =>
    (
      Object.values(pa).filter(notUndefined) as (
        | OffsetAdjustmentApplied
        | MasteringUpdate
        | OffsetChangeDetected
      )[]
    ).map((pa) => new ProcessActionPresentation(pa))
);

export const selectProcessActionsByFilteredJobAndActiveMeasurementTypes =
  createSelector(
    [
      (s: RootState) => s.focusedMachine.processActions,
      selectFocusedMachineJobTypeFilter,
      selectActiveMeasurementDetails,
    ],
    (allProcessActions, filteredJobs, activeMeasurementTypes) => {
      const processActions =
        Object.values(allProcessActions).filter(notUndefined);

      const masteringUpdateProcessActions = (
        processActions.filter(
          (f) => f.subType === "MasteringUpdate"
        ) as MasteringUpdate[]
      )
        // Filter by job
        .filter((pa) =>
          constants.processActionsOffsetAdjustmentOffsetTypes.includes(
            pa.subType!.toLowerCase()
          )
            ? !pa.masterJobName ||
              !filteredJobs?.length ||
              filteredJobs.includes(pa.masterJobName!)
            : pa
        );

      const toolOffsetProcessActions = (
        processActions.filter((f) =>
          ["OffsetAdjustmentApplied", "OffsetAdjustmentCalculation"].includes(
            f.subType
          )
        ) as (OffsetAdjustmentApplied | OffsetAdjustmentCalculation)[]
      )
        // Filter by job
        .filter((pa) =>
          constants.processActionsOffsetAdjustmentOffsetTypes.includes(
            pa.subType!.toLowerCase()
          )
            ? !pa.details?.processTrigger?.jobName ||
              !filteredJobs?.length ||
              filteredJobs.includes(pa.details.processTrigger.jobName)
            : pa
        )
        // Filter by feature (using featureName)
        .filter(
          (pa) =>
            !pa.details?.processTrigger?.characteristic?.featureName ||
            !activeMeasurementTypes.length ||
            activeMeasurementTypes.some(
              (m) =>
                m.featureName ===
                pa.details?.processTrigger?.characteristic.featureName
            )
        )
        // Filter by measurement
        .filter(
          (pa) =>
            !pa.details?.processTrigger?.characteristic?.name ||
            !activeMeasurementTypes.length ||
            activeMeasurementTypes.some(
              (m) => m.name === pa.details?.processTrigger?.characteristic?.name
            )
        );

      const toolChangeDetectedProcessActions = (
        processActions.filter(
          (pa) => pa.subType === "OffsetChangeDetected"
        ) as OffsetChangeDetected[]
      )
        // Filter by job
        .filter((pa) =>
          pa.details?.processTrigger?.jobName
            ? !pa.details?.processTrigger?.jobName ||
              !filteredJobs?.length ||
              filteredJobs.includes(pa.details.processTrigger.jobName)
            : pa
        )
        // Filter by feature (using featureName)
        .filter(
          (pa) =>
            !pa.details?.processTrigger?.characteristic?.featureName ||
            !activeMeasurementTypes.length ||
            activeMeasurementTypes.some(
              (m) =>
                m.featureName ===
                pa.details?.processTrigger?.characteristic?.featureName
            )
        )
        // Filter by measurement
        .filter(
          (pa) =>
            !pa.details?.processTrigger?.characteristic?.name ||
            !activeMeasurementTypes.length ||
            activeMeasurementTypes.some(
              (m) => m.name === pa.details?.processTrigger?.characteristic?.name
            )
        );
      const filteredActions = orderBy(
        ["created"],
        ["asc"],
        [
          ...masteringUpdateProcessActions,
          ...toolOffsetProcessActions,
          ...toolChangeDetectedProcessActions,
        ]
      );
      return filteredActions.map(
        (
          pa: OffsetAdjustmentApplied | OffsetChangeDetected | MasteringUpdate
        ) => new ProcessActionPresentation(pa)
      );
    }
  );

const selectAlertsByLevel = createSelector(
  [(s: RootState) => s.filter.active.alertLevel, selectAlerts],
  (activeAlertLevel, alerts) =>
    getFilteredResourceByKey("level")(alerts, activeAlertLevel)
);

// Alert levels which are subtypes of AlertLevel.offset
const offsetLevels = [
  AlertLevel.offsetError,
  AlertLevel.offsetInfo,
  AlertLevel.offsetWarning,
  AlertLevel.offsetOther,
  AlertLevel.offsetOtherInfo,
];

export const selectProcessActionsByLevel = createSelector(
  [
    (s: RootState) => s.filter.active.alertLevel as AlertLevel[],
    selectProcessActionsByFilteredJobAndActiveMeasurementTypes,
  ],
  (activeAlertLevels, processActions) => {
    if (activeAlertLevels.includes(AlertLevel.offset)) {
      return getFilteredResourceByKey("level")(processActions, [
        ...activeAlertLevels,
        ...offsetLevels,
      ]);
    } else {
      return getFilteredResourceByKey("level")(
        processActions,
        activeAlertLevels.filter((level) => !offsetLevels.includes(level))
      );
    }
  }
);

export const selectFilteredAlertNames = (state: RootState) =>
  state.focusedMachine.filters.alertName;

/** Filtered AlertPresentations and ProcessActionPresentations for the focused machine */
export const selectEventsToDisplay = createSelector(
  [selectAlertsByLevel, selectProcessActionsByLevel, selectFilteredAlertNames],
  (alerts, processActions, filteredAlertNames) => {
    const allAlerts = orderBy(
      ["created"],
      ["asc"],
      [...alerts, ...processActions]
    );
    return allAlerts.filter(
      (e) => !filteredAlertNames?.length || filteredAlertNames.includes(e.name)
    );
  }
);

export const selectTimelineWidth = (s: RootState) =>
  s.visualisations.machineAnalysis.dimensions.timeline.width;

export const selectTimelineTransform = (s: RootState) =>
  s.visualisations.machineAnalysis.transforms.timeline;

export const selectFocusedOrLatestJobViewType = (s: RootState) =>
  s.userViewPreference.focusedOrLatestJobViewType;

export const selectFocusedOrLatestJobViewMeasurementType = (s: RootState) =>
  s.userViewPreference
    .focusedOrLatestJobViewMeasurementType as FocusedOrLatestJobViewMeasurementType;

export const selectLoginView = (s: RootState) => s.loginView;

const selectTimelineDomain = createSelector(
  [
    (s: RootState) => s.params.start,
    (s: RootState) => s.params.end,
    (s: RootState) => s.focusedMachine.lastFetched,
    selectCurrentRoute,
  ],
  (start, end, lastFetched, route): [Date, Date] => {
    const startDate = isValidStartToken(start)
      ? getStartDate(start)
      : new Date(start);
    // if on a page with live refresh use lastFetched instead of end
    if (route === "machine analysis" && isValidStartToken(start)) {
      return [
        startDate,
        lastFetched || end ? new Date(lastFetched! ?? end!) : new Date(),
      ];
    } else {
      // end should be defined but we can fallback to now incase it isn't
      return [startDate, end ? new Date(end) : new Date()];
    }
  }
);

export const selectActiveJobTypes = (state: RootState) =>
  state.focusedMachine.filters.jobType;

/** Select jobs to show on the timeline (filtered by job type selection) */
export const selectFilteredJobs = createSelector(
  [selectFocusedMachineJobs, selectActiveJobTypes],
  (jobs, filters) =>
    filters.length ? jobs.filter((job) => filters.includes(job.name)) : jobs
);

export const selectFocusedMachineLatestJob = createSelector(
  [selectFocusedMachineJobs],
  (jobs): JobPresentation | undefined => jobs.at(-1)
);

const closestMeasurementToTime = (
  values:
    | TimeSeriesValueCompactValue[]
    | MeasurementCharacteristicPresentation[],
  time: Date,
  plotType: "actual" | "deviation"
) => {
  if (Array.isArray(values[0])) {
    const val = (values as TimeSeriesValueCompactValue[]).reduce(
      (
        a: TimeSeriesValueCompactValue | undefined,
        b: TimeSeriesValueCompactValue
      ) =>
        a &&
        Math.abs(differenceInMilliseconds(Date.parse(a[0]), time)) <
          Math.abs(differenceInMilliseconds(Date.parse(b[0]), time))
          ? a
          : b,
      undefined
    );
    return val
      ? {
          time: new Date(Date.parse(val[0])),
          value: val[1],
          toleranceType: undefined,
          verdict: "No Verdict",
          unit: val[2],
        }
      : undefined;
  } else {
    const val = (values as MeasurementCharacteristicPresentation[]).reduce(
      (
        a: MeasurementCharacteristicPresentation | undefined,
        b: MeasurementCharacteristicPresentation
      ) =>
        a &&
        Math.abs(differenceInMilliseconds(Date.parse(a.created), time)) <
          Math.abs(differenceInMilliseconds(Date.parse(b.created), time))
          ? a
          : b,
      undefined
    );
    return val
      ? {
          time: new Date(Date.parse(val.created)),
          value: plotType === "actual" ? val.actual : val.deviation,
          toleranceType: val.toleranceType,
          verdict: val.verdict,
          unit: val.unit,
        }
      : undefined;
  }
};

/** Unit conversion hints for the focused machine */
export const selectUnitHints = (s: RootState) => s.focusedMachine.hints;

/** The most recent known unit conversion hints for the focused machine */
const selectLatestUnitHints = (s: RootState) =>
  [...s.focusedMachine.hints]
    .sort((a, b) => a.created.localeCompare(b.created))
    .at(-1);

/** From the active series, selects the points closest to time.
 * Returns details of each series with a single value, sorted by parent and name. */
export const selectActiveSeriesValuesAtTime = (time: Date) =>
  createSelector(
    [
      selectSeriesColours,
      selectActiveMeasurementTypes,
      selectActiveTimeSeriesTypes,
      selectActiveMeasurementValuesWithDisplayUnits,
      selectTimeSeriesValuesWithDisplayUnits,
      selectLatestUnitHints,
      selectMeasurementPlotType,
    ],
    (
      colours,
      msd,
      tsd,
      measurementValues,
      timeSeriesValues,
      hints,
      plotType
    ) => {
      const result: {
        id: string;
        colour?: string;
        name: string;
        /** Tolerance name or group name */
        parent?: string;
        toleranceType?: string;
        unit?: string;
        time: Date;
        value: string;
        verdict: Verdict | string;
        displayPrecision: number;
      }[] = [
        ...msd.map((m) => {
          return {
            id: createUniqueMeasurementID(m),
            ...m,
            displayUnit: getDisplayUnit(m, hints?.value, hints?.overrides),
            displayPrecision: 4,
            dataOrigin: "measurement",
          };
        }),
        ...tsd.map((t) => {
          return {
            ...t,
            displayUnit: getDisplayUnit(t, hints?.value, hints?.overrides),
            displayPrecision:
              t.displayHints?.displayPrecision ?? DefaultTimeSeriesPrecision,
            dataOrigin: "timeseries",
          };
        }),
      ]
        .filter(notUndefined)
        .map((d) => {
          const data =
            (d.dataOrigin === "timeseries"
              ? timeSeriesValues[d.id]
              : measurementValues[d.id]
            )?.data ?? [];

          const val = closestMeasurementToTime(data, time, plotType);

          return val
            ? {
                id: d.id,
                colour: colours[d.id],
                name: d.name,
                parent:
                  // @ts-ignore type mashing
                  d.grouping || d.featureName || t`heading-Unassigned Series`,
                displayPrecision: d.displayPrecision,
                ...val,
                value:
                  typeof val.value === "string"
                    ? val.value
                    : tidyNumber(val.value),
                unit: val.unit ?? d.unit,
              }
            : undefined;
        })
        .filter(notUndefined)
        // Sort alphabetically by parent and name
        .sort(
          (a, b) =>
            a.parent.localeCompare(b.parent) ?? a.name.localeCompare(b.name)
        );

      return result;
    }
  );

export const selectTimelineScale = createSelector(
  [selectTimelineDomain, selectTimelineWidth, selectTimelineTransform],
  (domain, width, transform) => {
    return zoomIdentity
      .translate(transform.x, 0)
      .scale(transform.k)
      .rescaleX(scaleTime().range([0, width]).domain(domain));
  }
);

/**
 * Domain is set to min of extent / lower tolerance and max of extent / upper tolerance.
 */
export const makeSelectYAxisScale = (
  id: string,
  type: "measurement" | "sensor"
) =>
  type === "measurement"
    ? createSelector(
        [
          selectActiveMeasurementValuesWithDisplayUnits,
          selectYAxisTransform,
          selectLineChartHeight,
          selectMeasurementPlotType,
        ],
        (values, transforms, height, plotType) => {
          const data = values[id]?.data;
          if (!data || !data.length) return undefined;
          const minHeight = 0.5;
          const { nominal } = data[data.length - 1];
          let { upperLimit, lowerLimit } = data[data.length - 1];
          let upperLimitToRelative = nominal;
          let lowerLimitToRelative = nominal;

          if (lowerLimit == null) {
            lowerLimit = nominal;
          } else {
            lowerLimitToRelative = Math.min(nominal!, nominal! + lowerLimit!);
          }
          if (upperLimit == null) {
            upperLimit = nominal;
          } else {
            upperLimitToRelative = Math.max(nominal!, nominal! + upperLimit!);
          }
          const domainPadding = 0.05;
          const [extentLower, extentUpper] = extent(
            data,
            (x) => x[plotType]
          ) as [number, number];

          const upperScaleValue = Math.max(upperLimitToRelative!, extentUpper);
          const lowerScaleValue = Math.min(lowerLimitToRelative!, extentLower);
          const paddingValue =
            (upperScaleValue - lowerScaleValue) * domainPadding;

          const scale = scaleLinear()
            .range([height, minHeight])
            .domain([
              lowerScaleValue - paddingValue,
              upperScaleValue + paddingValue,
            ]);
          const transform = transforms[id];
          if (transform)
            return zoomIdentity
              .translate(0, transform.y)
              .scale(transform.k)
              .rescaleY(scale);
          else return scale;
        }
      )
    : createSelector(
        [
          (state: RootState) => selectTimeSeriesValuesPresentation(state, id),
          selectYAxisTransform,
          selectLineChartHeight,
          selectTimeSeriesLimits,
          selectTimeSeriesTypes,
          selectLatestUnitHints,
        ],
        (data, transforms, height, limits, timeseries, hints) => {
          if (!data) return undefined;

          const seriesType = timeseries.find((t) => t.id === id);
          let limitValues = limits[id]?.data.at(-1);

          if (seriesType && limitValues && hints) {
            const displayUnit = getDisplayUnit(
              seriesType,
              hints.value,
              hints?.overrides
            );
            const converter = units.convert[seriesType.unit]?.to[displayUnit];
            if (typeof converter === "function") {
              limitValues = convertLimits(limitValues, converter);
            }
          }

          let domainExtent = extent(data, (x) => x.value) as [number, number];
          const minHeight = 0.5;
          const noLimitPadding = 0.05;

          if (limitValues) {
            if (
              limitValues.upperDisplayLimit &&
              limitValues.upperDisplayLimit > domainExtent[1]
            )
              domainExtent = [
                limitValues.lowerDisplayLimit ?? 0,
                limitValues.upperDisplayLimit,
              ];
            else if (
              limitValues.upperOperatingLimit &&
              limitValues.upperOperatingLimit > domainExtent[1]
            )
              domainExtent = [
                limitValues.lowerOperatingLimit ?? 0,
                limitValues.upperOperatingLimit,
              ];
            else if (
              limitValues.upperWarningLimit &&
              limitValues.upperWarningLimit > domainExtent[1]
            )
              domainExtent = [
                limitValues.lowerWarningLimit ?? 0,
                limitValues.upperWarningLimit,
              ];
          } else {
            domainExtent = [
              domainExtent[0] * (1 - noLimitPadding),
              domainExtent[1] * (1 + noLimitPadding),
            ];
          }
          const transform = transforms[id];
          const scale = scaleLinear()
            .range([height, minHeight])
            .domain(domainExtent as [number, number]);
          if (transform)
            return zoomIdentity
              .translate(0, transform.y)
              .scale(transform.k)
              .rescaleY(scale);
          else return scale;
        }
      );

export const selectYDeviationAxisTransform = (s: RootState) =>
  s.visualisations.machineAnalysis.transforms.yDeviation;

export const selectYAxisTransform = (s: RootState) =>
  s.visualisations.machineAnalysis.transforms.yAxes;

export const selectLineChartHeight = (s: RootState) =>
  s.visualisations.machineAnalysis.dimensions.lineChart.height;

export const selectSeriesColours = (s: RootState) =>
  s.visualisations.machineAnalysis.seriesColours;

export const selectFocusedJob = (s: RootState) => s.focusedJob.details;

export const selectFocusedJobSortingDetails = (s: RootState) =>
  s.focusedJob.singleJobTable;

export const selectLatestJob = (s: RootState) => s.latestJob.details;

export const selectIsJobSelectedManually = (s: RootState) =>
  s.global.jobSelectedManuallyFromJobsTimeLine;

/** The details of the focused or latest job */
export const selectFocusedOrLatestJob = createSelector(
  [
    selectLatestJob,
    selectFocusedJob,
    selectFocusedMachineJobs,
    selectIsJobSelectedManually,
  ],
  (latest, main, all, state) => (state ? main : latest ?? all.at(-1))
);

/** More details of the focused or latest job */
export const selectMainJobMoreDetails = createSelector(
  [(s: RootState) => s.focusedJob, (s: RootState) => s.latestJob],
  (focusedJob, latestJob) => {
    return focusedJob.moreDetails ?? latestJob.moreDetails;
  }
);

/** Related jobs of the focused or latest job */
export const selectRelatedJobs = (s: RootState) => {
  return s.global.jobSelectedManuallyFromJobsTimeLine
    ? s.focusedJob.relatedJobs
    : s.latestJob.relatedJobs;
};

/** Current sort column for the job table */
export const selectJobSortColumn = createSelector(
  [(s: RootState) => s.focusedJob ?? s.latestJob],
  (j) => j?.relatedFocusedJob.sortColumn
);

/** Current sort direction for column in the job table */
export const selectJobSortDirection = createSelector(
  [(s: RootState) => s.focusedJob ?? s.latestJob],
  (j) => j?.relatedFocusedJob.sortDirection
);
/**
 * Returns the measurement types of the focused or latest job and its related jobs, sorted based on absolute value of normalisedDeviation.
 * Unique across all focused jobs (relative and main) but sorted on main focused job.
 */
export const selectFocusedJobsSortedMeasurementTypes = createSelector(
  [selectFocusedOrLatestJob, selectRelatedJobs, selectJobSortDirection],
  (mainJob, relatedJobs, sortDirection) => {
    if (!mainJob || mainJob.type !== "metrology") return [];
    // Create an array of all measurement characteristics types (featureName and name)
    const measurements = [mainJob, ...Object.values(relatedJobs)].flatMap(
      (job) => {
        if (job && job.type === "metrology") {
          const sorted = sortBy(
            (mc) =>
              mc.normalisedDeviation !== undefined
                ? Math.abs(mc.normalisedDeviation)
                : null,
            job.measurementCharacteristics ?? []
          );
          return sortDirection === "desc" ? sorted.reverse() : sorted;
        } else return [];
      }
    );
    // Pick the unique measurement types
    const uniqMeasurements = Object.values(
      measurements.reduce(
        (uniq, measurement) =>
          uniq[measurement.featureName + measurement.name]
            ? uniq
            : {
                ...uniq,
                [measurement.featureName + measurement.name]: measurement,
              },
        {} as MappyAbsolute<{
          featureName?: string;
          name: string;
          toleranceType?: string;
          unit?: string;
        }>
      )
    );
    return uniqMeasurements;
  }
);

/**
 * Gets the measurements of a related group of jobs, sorted based on absolute of normalisedDeviation.
 * Unique across all related jobs (relative and main) but sorted on main focused job.
 * @returns a sorted array of measurement metadata objects
 */
export const selectRelatedFocusedJobSortedMeasurementTypes = (
  relatedfocusedJob: undefined | JobPresentation
) =>
  createSelector(
    [selectRelatedJobs, selectJobSortDirection],
    (relatedfocusedJobs, sortDirection) => {
      if (!relatedfocusedJob || relatedfocusedJob.type !== "metrology")
        return [];
      // Create an array of all measurement characteristics types (featureName and name)
      const measurements = [
        relatedfocusedJob,
        ...Object.values(relatedfocusedJobs),
      ].flatMap((job) => {
        if (job && job.type === "metrology") {
          const sorted = sortBy(
            (mc) =>
              mc.normalisedDeviation !== undefined
                ? Math.abs(mc.normalisedDeviation)
                : null,
            job.measurementCharacteristics ?? []
          );
          return sortDirection === "desc" ? sorted.reverse() : sorted;
        } else return [];
      });
      // Pick the unique measurement types
      const uniqMeasurements = Object.values(
        measurements.reduce(
          (uniq, measurement) =>
            uniq[measurement.featureName + measurement.name]
              ? uniq
              : {
                  ...uniq,
                  [measurement.featureName + measurement.name]: measurement,
                },
          {} as MappyAbsolute<{
            featureName?: string;
            name: string;
            toleranceType?: string;
            unit?: string;
          }>
        )
      );
      return uniqMeasurements;
    }
  );

export const selectMainMeasurement = (s: RootState) =>
  s.focusedMachine.mainMeasurementDetail;

export const selectTimelineIsZoomed = createSelector(
  [selectTimelineTransform],
  (transform): boolean => transform.k !== zoomIdentity.k
);

export const selectLastFetchedDateTime = createSelector(
  [(s: RootState) => s.focusedMachine.lastFetched],
  (lastFetched): Date | null => (lastFetched ? new Date(lastFetched) : null)
);

export const selectRefreshMode = createSelector(
  [selectIsRollingMode, selectTimelineIsZoomed],
  (isRollingMode, isZoomed): RefreshMode =>
    isRollingMode && !isZoomed ? "refresh" : "locked"
);

export const selectAlertTypeReasons = createSelector(
  [selectAlerts, selectStates, selectStartAndEndDates],
  (selectAlerts, selectStates, dates): AlertTypeReason[] => {
    const errorAlerts = selectAlerts.filter(
      (alert) => alert.level === AlertLevel.error
    );
    const groups = groupBy((alert) => alert.name, errorAlerts);
    const totalRunningInSeconds = calculateUptime(
      selectStates,
      dates[0],
      dates[1]
    );
    const reasons = Object.keys(groups).map((key) => ({
      name: key,
      total: groups[key].length,
    }));
    const total = reasons.reduce(
      (total, nextReason) => total + nextReason.total,
      0
    );
    const sortedReasons = orderBy(["total"], ["desc"], reasons).map(
      (reason) => ({
        ...reason,
        percentage: (reason.total / total) * 100,
      })
    );
    const cumulative = sortedReasons.reduce(
      (a, b) => a.concat((a[a.length - 1] || 0) + b.percentage),
      [] as number[]
    );
    return sortedReasons.map((reason, i) => {
      const date = fixedTimespanFormat(totalRunningInSeconds / reason.total);
      return {
        ...reason,
        id: i + 1,
        cumulativePercentage: cumulative[i],
        meanTimeBeforeFail: date,
      };
    });
  }
);

export const selectMeasurementTypes = createSelector(
  [(s: RootState) => s.focusedMachine.measurementDetails],
  (measurements) => Object.values(measurements).filter(notUndefined)
);

export const selectMeasurementsAsSeries = createSelector(
  [
    selectMeasurementTypes,
    selectFocusedJob,
    selectMainMeasurement,
    selectSeriesColours,
    selectLatestUnitHints,
  ],
  (types, focusedJob, focusedMeasurement, seriesColours, hints): Series[] => {
    let focusedJobMeasurementTypes = types;
    if (focusedJob && focusedJob.type === "metrology") {
      focusedJobMeasurementTypes = types.filter((item) =>
        focusedJob?.measurementCharacteristics?.some(
          ({ name, featureName }) =>
            featureName === item.featureName && name === item.name
        )
      );
    }

    return focusedJobMeasurementTypes.map((measurement) => {
      const id = createUniqueMeasurementID(measurement);
      const colour = (measurement.active && seriesColours[id]) || "#0004";

      return {
        type: "measurement",
        id,
        toleranceType: measurement.toleranceType,
        active: measurement.active,
        colour: colour,
        loading: false,
        main: focusedMeasurement === id,
        name: measurement.name,
        featureGroups: measurement.featureGroups,
        parent: measurement.featureName,
        unit: getDisplayUnit(measurement, hints?.value, hints?.overrides),
      };
    });
  }
);

/** Selects details of all time series for the focused machine */
export const selectTimeSeriesTypes = createSelector(
  [(s: RootState) => s.focusedMachine.timeSeriesDetails],
  (timeSeries) => Object.values(timeSeries).filter(notUndefined)
);

/** Selects details of active time series for the focused machine */
export const selectActiveTimeSeriesTypes = createSelector(
  [selectTimeSeriesTypes],
  (details) => details.filter(({ active }) => active)
);

export const selectMeasurementPlotType = (s: RootState) =>
  s.focusedMachine.measurementPlotType;

export const selectTimeSeriesAsSeries = createSelector(
  [
    selectTimeSeriesTypes,
    selectSeriesColours,
    selectLatestUnitHints,
    selectFocusedOrLatestJob,
  ],
  (types, seriesColours, hints): Series[] =>
    types.map((timeSeries): Series => {
      return {
        type: "sensor",
        id: timeSeries.id,
        name: timeSeries.name,
        active: timeSeries.active,
        colour: (timeSeries.active && seriesColours[timeSeries.id]) || "#0004",
        loading: false,
        parent: timeSeries.grouping,
        unit: getDisplayUnit(timeSeries, hints?.value, hints?.overrides),
      };
    })
);

/** Selects MeasurementTypeDetails for active measurements on the focused machine */
export const selectActiveMeasurementTypes = createSelector(
  [selectMeasurementTypes],
  (measurements) =>
    Object.values(measurements).filter((measurement) => measurement.active)
);

/** Selects a list of type IDs for active measurements on the focused machine */
export const selectActiveMeasurementTypeIds = createSelector(
  [selectActiveMeasurementTypes],
  (measurements) => measurements.map(createUniqueMeasurementID)
);

/** Selects the Mappy of raw (unconverted) measurement values for all Jobs on the focused Machine */
const selectMeasurementValues = createSelector(
  [
    (s: RootState) => s.focusedMachine.measurementValues,
    selectActiveMeasurementTypeIds,
    selectFocusedMachineJobTypeFilter,
    selectFilteredJobs,
  ],
  // Only select measurements for the currently selected jobs
  (measurements, activeIds, jobFilter, jobs) => {
    // Skip if no jobs selected
    // TODO: also skip if all jobs selected? Or is this filtering still useful?
    if (jobFilter.length === 0) return measurements;

    const current = {} as typeof measurements;
    for (const id in measurements) {
      // Filter to the series whose name and feature name are in the current job
      if (!activeIds.includes(id)) continue;
      // That still includes matching measurements from other jobs, so we have to filter by timebox

      const m = measurements[id]!;
      current[id] = {
        data: m.data.filter((d) =>
          // created date is within a filtered job run
          jobs.some(
            (j) => j.start <= d.created && (!j.end || j.end >= d.created)
          )
        ),
        params: m.params,
      };
    }

    return current;
  }
);

/** Selects details of empty time series for the focused machine */
export const selectActiveTimeSeriesTypesWithoutData = createSelector(
  [
    selectActiveTimeSeriesTypes,
    (s: RootState) => s.focusedMachine.timeSeriesValues,
  ],
  (activeTimeSeriesTypes, values) =>
    activeTimeSeriesTypes.filter(({ id }) => values[id] === undefined)
);

export const selectTimeSeriesLimits = (s: RootState) =>
  s.focusedMachine.timeSeriesLimits;

export const selectTimeSeriesValues = (s: RootState) =>
  s.focusedMachine.timeSeriesValues;

/** Returns `f(x)` unless `x` is undefined/null, in which case it returns undefined */
const ifDefined = function <T, O>(x: T | undefined, f: (p: T) => O) {
  if (x === undefined || x === null) return undefined;
  else return f(x);
};

/** Selects the measurements for the active series on the focused machine, with units converted  */
export const selectActiveMeasurementValuesWithDisplayUnits = createSelector(
  [selectMeasurementValues, selectMeasurementTypes, selectLatestUnitHints],
  (msv, types, hints) => {
    const result: typeof msv = {};
    const keys = Object.keys(msv);
    for (const key of keys) {
      const mc = msv[key]!;
      // Clone all to result
      result[key] = { ...mc };

      const seriesType = types.find(
        (t) => createUniqueMeasurementID(t) === key
      );
      if (!seriesType) continue;
      // Take the last unit hint in the query timebox
      const seriesUnit = units.standardise(seriesType.unit);
      const displayUnit = getDisplayUnit(
        seriesType,
        hints?.value,
        hints?.overrides
      );

      // Can we do a conversion?
      if (displayUnit !== seriesUnit) {
        result[key]!.data = msv[key]!.data.map((m) => {
          return convertMeasurementCharacteristicUnits(
            m,
            seriesUnit,
            displayUnit
          );
        });
      }
    }
    return result;
  }
);

export const selectTimeSeriesValuesWithDisplayUnits = createSelector(
  [selectTimeSeriesValues, selectTimeSeriesTypes, selectLatestUnitHints],
  (tsv, types, hints) => {
    const result: typeof tsv = {};
    const keys = Object.keys(tsv);

    for (const key of keys) {
      const currentTsv = tsv[key]!;

      const seriesType = types.find((t) => t.id === key);
      if (!seriesType) continue;

      const seriesUnit = units.standardise(seriesType.unit);
      const displayUnit = getDisplayUnit(
        seriesType,
        hints?.value,
        hints?.overrides
      );

      // Clone all to result
      result[key] = {
        ...currentTsv,
        data: currentTsv.data.map((d) => {
          return [d[0], d[1], displayUnit];
        }),
      };

      // Can we do a conversion?
      if (displayUnit !== seriesUnit) {
        const precision =
          seriesType.displayHints?.displayPrecision ??
          DefaultTimeSeriesPrecision;
        result[key]!.data = currentTsv.data.map((d) => {
          return [
            d[0],
            tidyNumber(
              units.convert[seriesUnit].to[displayUnit](Number(d[1])),
              precision
            ),
            displayUnit,
          ];
        });
      }
    }
    return result;
  }
);

/** Gets the data for the TimeSeries matching seriesId, with display unit and converted value */
export const selectTimeSeriesValuesPresentation = createSelector(
  [
    selectTimeSeriesValues,
    selectTimeSeriesTypes,
    selectLatestUnitHints,
    (_state, seriesId: GUID) => seriesId,
  ],
  (tsv, types, hints, seriesId) => {
    const currentTsv = tsv[seriesId]!;
    const seriesType = types.find((t) => t.id === seriesId);
    if (!currentTsv || !seriesType)
      return [] as SeriesCompactValuePresentation[];

    const seriesUnit = units.standardise(seriesType.unit);
    const displayUnit = getDisplayUnit(
      seriesType,
      hints?.value,
      hints?.overrides
    );
    const precision =
      seriesType.displayHints?.displayPrecision ?? DefaultTimeSeriesPrecision;

    let converter = (x: number) => x;

    if (
      displayUnit !== seriesUnit &&
      typeof units.convert[seriesUnit].to[displayUnit] === "function"
    ) {
      converter = units.convert[seriesUnit].to[displayUnit];
    }

    return currentTsv.data.map((d) => {
      const value = converter(Number(d[1]));
      return {
        date: d[0],
        unit: displayUnit,
        value,
        display: tidyNumber(value, precision),
      } as SeriesCompactValuePresentation;
    });
  }
);

/**
 * Returns the measurements of the focused or latest job (if any), sorted worst to best.
 */
export const selectFocusedOrLatestJobSortedMeasurements = createSelector(
  [selectFocusedOrLatestJob, selectLatestUnitHints],
  (job, hints) => {
    if (
      !job ||
      job.type !== "metrology" ||
      !job.measurementCharacteristics?.length
    )
      return null;

    return job.measurementCharacteristics
      .flatMap((mc) => {
        const seriesUnit = units.standardise(mc.unit);
        const displayUnit = getDisplayUnit(mc, hints?.value, hints?.overrides);
        return convertMeasurementCharacteristicUnits(
          mc,
          seriesUnit,
          displayUnit
        );
      })
      ?.slice()
      .sort(sortWorstToBest);
  }
);

/** Selects focused/latest and related jobs on the selected machine, converted to the units of the focused/latest job */
export const selectRelatedJobsWithDisplayUnits = createSelector(
  [selectFocusedOrLatestJob, selectRelatedJobs, selectLatestUnitHints],
  (focusedJob, relatedJobs, hints) => {
    if (focusedJob?.type !== "metrology") return {};

    const allJobs = {
      ...relatedJobs,
      [focusedJob.id]: focusedJob,
    };
    const convertedJobs: Mappy<
      JobPresentation & { type: "metrology" | "mastering" }
    > = {};

    for (const key in allJobs) {
      // @ts-ignore access object by index
      const job = allJobs[key];
      if (job && job.type === "metrology") {
        //@ts-ignore
        convertedJobs[key] = {
          ...job,
          measurementCharacteristics: (
            job.measurementCharacteristics ?? []
          ).map((mc: MeasurementCharacteristicPresentation) => {
            const seriesUnit = units.standardise(mc.unit);
            const displayUnit = getDisplayUnit(
              mc,
              hints?.value,
              hints?.overrides
            );
            return convertMeasurementCharacteristicUnits(
              mc,
              seriesUnit,
              displayUnit
            );
          }),
        };
      }
    }
    return convertedJobs;
  }
);

/** Gets the measurement unit to display for a series or measurement type.
 * This is the series' own `unitDisplayHint` property,
 * or the unit from process hints,
 * or the standardised version of its unit.
 * @param seriesType A measurement series or timeseries with name and unit information
 * @param hints The unit type conversions from process hint events, as a {unitType: unitSymbol} dictionary
 * */
const getDisplayUnit = (
  seriesType: {
    name: string;
    unit?: string;
    unitDisplayHint?: string;
    toleranceType?: string;
    toleranceSubType?: string;
  },
  hints?: Dictionary<string>,
  overrides?: UnitDisplayHintOverride[]
): string => {
  if (!seriesType?.unit) return units.standardise(undefined);
  const unit = units.standardise(seriesType.unit);
  const converter = units.convert[unit];
  if (!converter || !hints) {
    return unit;
  }

  const matchingHintOverride = overrides?.map(
    (p) => p.type === "MeasurementCharacteristic"
  )
    ? overrides?.find(
        (hintOverride) =>
          (!hintOverride.name || hintOverride.name === seriesType.name) &&
          (!hintOverride.toleranceType ||
            hintOverride.toleranceType === seriesType.toleranceType) &&
          (!hintOverride.toleranceSubType ||
            hintOverride.toleranceSubType === seriesType.toleranceSubType) &&
          converter.type.toLowerCase() === hintOverride.unitType.toLowerCase()
      )
    : overrides?.find(
        (hintOverride) =>
          hintOverride.name === seriesType.name &&
          converter.type.toLowerCase() === hintOverride.unitType.toLowerCase()
      );
  if (matchingHintOverride) {
    const hintOverride = units.standardise(matchingHintOverride.unit);
    // if we can convert to the override unit or the override is the same unit
    if (hintOverride in converter.to || hintOverride === unit) {
      return hintOverride;
    }
  }

  // Time series hints
  const seriesHint = units.standardise(seriesType.unitDisplayHint);
  if (seriesHint in converter.to) {
    return seriesHint;
  }
  // Process hints
  const hintUnit = units.standardise(hints[converter.type]);
  if (converter.type in hints && hintUnit in converter.to) {
    return hintUnit;
  }

  return unit;
};

export const selectStartupState = (s: RootState) => s.startupState;

export const selectFocusedMachineLastFetched = (s: RootState) =>
  s.focusedMachine.lastFetched;

export const selectMachineAnalysisView = (s: RootState) =>
  s.userViewPreference.machineAnalysisView;

export const selectAwaitingUnitHints = (s: RootState) =>
  s.focusedMachine.awaitingHints;

export const selectActiveFilters = (s: RootState) => s.filter.active;

export const selectAreFiltersModified = createSelector(
  [selectActiveFilters],
  (activeFilter) => {
    const filter = initialFilterState();
    return Object.getOwnPropertyNames(filter).some(
      // @ts-expect-error this is an object it's okay!
      (k) => xor(filter[k], activeFilter[k]).length > 0
    );
  }
);

export const selectYDeviationScale = createSelector(
  [
    selectYDeviationAxisTransform,
    selectLineChartHeight,
    selectActiveMeasurementDetails,
    selectActiveMeasurementValuesWithDisplayUnits,
    selectMainMeasurement,
  ],
  (transform, height, activeTypes, values, mainMeasurementId) => {
    const activeValues = activeTypes
      .map((type) => values[createUniqueMeasurementID(type)]?.data)
      .filter(notUndefined)
      .flatMap((x) => x);
    const mainValues = mainMeasurementId
      ? values[mainMeasurementId]?.data
      : undefined;

    let domain: [number, number] = [-1, 1];
    const domainPadding = 0.05;
    if (mainMeasurementId && mainValues?.length) {
      // when a main measurement exists we want to use it to calculate the domain.
      // domain is min/max of main latest value upper/lower limit and extent of all active measurement values.
      const { nominal } = mainValues[mainValues.length - 1];
      let { upperLimit, lowerLimit } = mainValues[mainValues.length - 1];

      const [extentLower, extentUpper] = extent(
        activeValues,
        (x) => x.deviation
      ) as [number, number];

      if (lowerLimit == null) {
        lowerLimit = nominal;
      }
      if (upperLimit == null) {
        upperLimit = nominal;
      }

      // Explicitly check for undefined rather than 'upperLimit ?'
      // as this returns false if upperLimit is 0.
      const absoluteUpperLimit =
        upperLimit !== undefined
          ? Math.max(upperLimit, extentUpper)
          : extentUpper;
      const absoluteLowerLimit =
        lowerLimit !== undefined
          ? Math.min(lowerLimit, extentLower)
          : extentLower;

      const paddingValue =
        (absoluteUpperLimit - absoluteLowerLimit) * domainPadding;
      domain = [
        absoluteLowerLimit - paddingValue,
        absoluteUpperLimit + paddingValue,
      ];
    } else if (activeValues.length) {
      // when there isn't a main measurement we just use the extent of the active measurement values.
      const [extentLower, extentUpper] = extent(
        activeValues,
        (x) => x.deviation
      ) as [number, number];
      const paddingValue = (extentUpper - extentLower) * domainPadding;
      domain = [extentLower - paddingValue, extentUpper + paddingValue];
    }
    const scale = scaleLinear().range([height, 0]).domain(domain);
    if (transform)
      return zoomIdentity
        .translate(0, transform.y)
        .scale(transform.k)
        .rescaleY(scale);
    else return scale;
  }
);

export const selectYDeviationUnit = createSelector(
  [selectActiveMeasurementDetails, selectLatestUnitHints],
  (activeTypes, hints) => {
    const uniqueUnits: string[] = activeTypes.reduce<string[]>(
      (units, mType) => {
        if (!mType.unit) return units;

        return [
          ...units,
          getDisplayUnit(mType, hints?.value, hints?.overrides) ?? "",
        ];
      },
      []
    );
    return uniqueUnits.length === 1 ? uniqueUnits[0] : emptyStateText;
  }
);

export const selectActiveSidebarSections = (s: RootState) =>
  s.userViewPreference.activeSidebarSections;

export const selectSidebarFilters = (s: RootState) =>
  s.userViewPreference.sidebarFilters;

export const selectAssignedLicences = (s: RootState) => s.assignedLicences;

export const selectTotalNumberOfAssignedLicences = createSelector(
  [selectAssignedLicences],
  (assigned) => assigned.length
);

export const selectUnassignedLicences = (s: RootState) => s.unassignedLicences;

export const selectTotalNumberOfUnassignedLicences = createSelector(
  [selectUnassignedLicences],
  (unassigned) => unassigned.length
);

export const selectTotalNumberOfLicences = createSelector(
  [selectAssignedLicences, selectUnassignedLicences],
  (assigned, unassigned) => assigned.length + unassigned.length
);

export const selectlicensedMachines = createSelector(
  [selectMachines, selectAssignedLicences],
  (machines, assignedLicences) => {
    return machines.filter((machine) =>
      assignedLicences.find(({ machineId }) => machineId === machine.id)
    );
  }
);

export const selectManageAssetsSelectedNode = (s: RootState) =>
  s.manageAssets.singleSelectedNode;

/** The machine being edited in Manage Assets (API state with no unsaved changes) */
export const selectManageAssetsSelectedMachine = createSelector(
  [selectMachines, selectManageAssetsSelectedNode],
  (machines, id) =>
    id ? machines.find((machine) => machine.id === id) : undefined
);

/** The machine being edited in Manage Assets, with any unsaved changes. This includes the IPC config parts of the form, if available. */
export const selectManageAssetsSelectedMachineWithEdits = createSelector(
  [
    selectManageAssetsSelectedMachine,
    (s: RootState) => s.manageAssets.machineEdits,
  ],
  (machine, edits) => {
    if (!machine) return undefined;
    if (edits?.id !== machine.id) return machine;
    return { ...machine, ...removeUndefinedProps(edits) };
  }
);

/** The IPC configuration for the machine being edited in Manage Assets (API state with no unsaved changes) */
export const selectManageAssetsSelectedMachineConfig = createSelector(
  [
    selectManageAssetsSelectedMachine,
    (s: RootState) => s.manageAssets.machineConfigurations,
  ],
  (machine, configs) => {
    if (!machine || machine.type !== MachineType.MachineTool) return undefined;
    return configs[machine.id];
  }
);

/** The IPC configuration for the machine being edited in Manage Assets, with any unsaved changes */
export const selectManageAssetsSelectedMachineConfigWithEdits = createSelector(
  [
    selectManageAssetsSelectedMachineConfig,
    (s: RootState) => s.manageAssets.machineConfigEdits,
  ],
  (config, edits) => {
    if (!config) return undefined;
    if (edits?.machineId !== config.machineId)
      return config as MachineConfiguration;

    const changes = removeUndefinedProps(edits);

    const conf = {
      ...config,
      ...changes,
      details: {
        dispatchMethod:
          changes?.details?.dispatchMethod ?? config.details?.dispatchMethod,
        controller: {
          ...config.details?.controller,
          ...changes?.details?.controller,
        },
        connectionInfo: {
          ...config.details?.connectionInfo,
          ...changes?.details?.connectionInfo,
        },
        configurationInfo: {
          ...config.details?.configurationInfo,
          ...changes?.details?.configurationInfo,
        },
      },
    } as MachineConfiguration;
    return conf;
  }
);

export const selectManageAssetsMachineFormIsDirty = createSelector(
  [
    selectManageAssetsSelectedMachine,
    selectManageAssetsSelectedMachineWithEdits,
    selectManageAssetsSelectedMachineConfig,
    selectManageAssetsSelectedMachineConfigWithEdits,
  ],
  (baseMachine, editedMachine, baseConfig, editedConfig) => {
    // If the current dispatch method is none, then there are no details to compare.
    // dispatchMethod will not exist on the baseConfig if previously saved as 'none',
    // as it exists in the details. Active being false will be equivalent to
    // dispatchMethod being 'none' i.e. no changes. If active is true then there have
    // been changes from the base.
    if (editedConfig?.details?.dispatchMethod === "None")
      return baseConfig?.active;

    const connectionAddressChanged =
      editedConfig?.details?.connectionInfo?.addressType === "Hostname"
        ? editedConfig?.details?.connectionInfo?.hostname !==
          baseConfig?.details?.connectionInfo?.hostname
        : editedConfig?.details?.connectionInfo?.ipAddress !==
          baseConfig?.details?.connectionInfo?.ipAddress;

    return (
      // Just compare the fields we can change in the form, so API refresh can't invalidate it
      // IP address needs special comparison; the empty state can be null, undefined or ""
      (editedMachine?.ipAddress || null) !== (baseMachine?.ipAddress || null) ||
      editedMachine?.locationId !== baseMachine?.locationId ||
      editedMachine?.make !== baseMachine?.make ||
      editedMachine?.model !== baseMachine?.model ||
      editedMachine?.name !== baseMachine?.name ||
      editedMachine?.serialNumber !== baseMachine?.serialNumber ||
      editedConfig?.details?.dispatchMethod !==
        baseConfig?.details?.dispatchMethod ||
      // Controller
      editedConfig?.details?.controller?.make !==
        baseConfig?.details?.controller?.make ||
      editedConfig?.details?.controller?.model !==
        baseConfig?.details?.controller?.model ||
      editedConfig?.details?.controller?.units !==
        baseConfig?.details?.controller?.units ||
      editedConfig?.details?.controller?.unitsPrecision !==
        baseConfig?.details?.controller?.unitsPrecision ||
      // Connection Info
      connectionAddressChanged ||
      (editedConfig &&
        baseConfig &&
        editedConfig.details &&
        baseConfig.details &&
        (Number(editedConfig.details.connectionInfo?.portNumber) !==
          Number(baseConfig.details.connectionInfo?.portNumber) ||
          Number(editedConfig.details.connectionInfo?.connectionTimeoutSecs) !==
            Number(baseConfig.details.connectionInfo?.connectionTimeoutSecs) ||
          editedConfig.details.connectionInfo?.useServer !==
            baseConfig.details.connectionInfo?.useServer ||
          // Configuration Info
          Number(editedConfig.details.configurationInfo?.syncVariable) !==
            Number(baseConfig.details.configurationInfo?.syncVariable) ||
          Number(editedConfig.details.configurationInfo?.alarmVariable) !==
            Number(baseConfig.details.configurationInfo?.alarmVariable))) ||
      editedConfig?.details?.connectionInfo?.deviceNumber !==
        baseConfig?.details?.connectionInfo?.deviceNumber ||
      editedConfig?.details?.connectionInfo?.channel !==
        baseConfig?.details?.connectionInfo?.channel ||
      editedConfig?.details?.connectionInfo?.appName !==
        baseConfig?.details?.connectionInfo?.appName ||
      editedConfig?.details?.connectionInfo?.appPassword !==
        baseConfig?.details?.connectionInfo?.appPassword ||
      editedConfig?.details?.connectionInfo?.connectionType !==
        baseConfig?.details?.connectionInfo?.connectionType
    );
  }
);

export const selectManageProvisioningSelectedRequest = createSelector(
  [
    (s: RootState) => s.manageProvisioning.machines,
    (s: RootState) => s.manageProvisioning.selectedRegistrationId,
  ],
  (machines, id) => {
    if (machines && id)
      return machines.find((machine) => machine.registrationId === id);
  }
);

export type LicenceSummary = {
  total: number;
  assigned: number;
  unassigned: number;
  machines: number;
  licences: Licence[];
};

export const selectLicenceSummary = createSelector(
  [selectMachines, selectAssignedLicences, selectUnassignedLicences],
  (
    machines,
    assignedLicences,
    unassignedLicences
  ): MappyAbsolute<LicenceSummary> => {
    const allLicences = [...assignedLicences, ...unassignedLicences];

    const allLicencesGroupedByType = groupBy(
      (licence) => getMachineTypeAsString(licence.type),
      allLicences
    );

    const uniqueLicenceTypes = [
      ...new Set(
        allLicences.map((licence) => getMachineTypeAsString(licence.type))
      ),
    ];

    const summaries = uniqueLicenceTypes.map((type) => {
      const summary = allLicencesGroupedByType[type];

      const unsortedTypeLicences = unassignedLicences.filter(
        (licence) => getMachineTypeAsString(licence.type) === type
      );
      const licences = orderBy(
        ["transferred", "transferNumber"],
        ["desc", "asc"],
        unsortedTypeLicences
      );
      return summary
        ? {
            type,
            total: summary.length,
            assigned: summary.filter(
              (licence) =>
                licence.machineId !== undefined && licence.machineId !== null
            ).length,
            unassigned: summary.filter(
              (licence) =>
                licence.machineId === undefined || licence.machineId === null
            ).length,
            machines: machines.filter(
              (machine) => getMachineTypeAsString(machine.type) === type
            ).length,
            licences,
          }
        : {
            type,
            total: 0,
            assigned: 0,
            unassigned: 0,
            machines: machines.filter(
              (machine) => getMachineTypeAsString(machine.type) === type
            ).length,
            licences,
          };
    });

    const summaryMap: MappyAbsolute<{
      total: number;
      assigned: number;
      unassigned: number;
      machines: number;
      licences: Licence[];
    }> = {};

    for (const summary of summaries) {
      summaryMap[summary.type] = summary;
    }

    return summaryMap;
  }
);

export const selectEnableIPC = createSelector(
  [
    (s: RootState) => s.locationEntityPermissions,
    (state: RootState) => state.systemPermissions,
    selectMachines,
    selectLocations,
  ],
  (locationEntityPermissions, sysPermissions, machines, locations) => {
    const licensedIPCMachines = machines.filter(
      (m) =>
        m.isLicensed &&
        m.type === MachineType.Service &&
        /^ipc ?service$/i.test(m.subType || m.model)
    );
    if (!licensedIPCMachines.length) return false;

    if (
      sysPermissions?.includes("dataAdmin") ||
      sysPermissions?.includes("tenantAdmin")
    )
      return true;

    return licensedIPCMachines.some((m) => {
      const loc = locations.find(({ id }) => id === m.locationId);
      return (
        locationEntityPermissions &&
        locationEntityPermissions.some((l) => {
          return (
            (l.entityId === m.locationId && l.permission === "Admin") ||
            (loc?.ancestors &&
              loc?.ancestors.some(
                (s) => s === l.entityId && l.permission === "Admin"
              ))
          );
        })
      );
    });
  }
);

export const selectLastFetchedDate = (type: DataFetchedType) =>
  createSelector(
    [
      selectStartAndEnd,
      (state: RootState) => state.global.lastFetched,
      selectStates,
      selectFocusedMachineJobs,
      selectAlerts,
      selectTimeSeriesValues,
      selectMeasurementValues,
      selectProcessActions,
      selectUnitHints,
    ],
    (
      [, to],
      lastFetched,
      states,
      focusedMachineJobs,
      alerts,
      timeSeriesValues,
      measurementSeriesValues,
      processActions,
      hints
    ) => {
      let from = lastFetched ? lastFetched.toISOString() : to;
      switch (type) {
        case dataFetchedType[0]: // states
          from = states?.at(-1)?.created || from;
          break;
        case dataFetchedType[1]: // jobs (use start, not end – unlike simple events, jobs change after they're created)
          from = focusedMachineJobs?.at(-1)?.start || from;
          break;
        case dataFetchedType[2]: // alerts
          if (alerts.length && alerts.at(-1)?.created !== undefined)
            from = alerts?.at(-1)?.created?.toISOString() || from;
          break;
        case dataFetchedType[3]: // tsv
          if (timeSeriesValues !== undefined) {
            const timeSeriesData = Object.values(timeSeriesValues)[0]?.data;
            from = timeSeriesData?.at(-1)?.[0] || from;
          }
          break;
        case dataFetchedType[4]: //msv
          if (measurementSeriesValues !== undefined) {
            const measurementData = Object.values(measurementSeriesValues)[0]
              ?.data;
            from = measurementData?.at(-1)?.created || from;
          }
          break;
        case dataFetchedType[5]: // processActions
          from = processActions.at(-1)?.created.toISOString() || from;
          break;
        case dataFetchedType[6]: // unitHints
          from = hints.at(-1)?.created || from;
          break;
        default:
          assertUnreachable(type);
      }
      // Adding a millisecond to the last record's date to avoid duplicate values
      return nextMillisecondISOString(from);
    }
  );

export const selectServerLicenceState = (s: RootState) =>
  s.manageServer.serverLicenceState;

export const isAboutVersionVisible = (state: RootState) =>
  state.aboutPopup.visibility;

function convertMeasurementCharacteristicUnits(
  measurement: MeasurementCharacteristicPresentation,
  seriesUnit: string,
  displayUnit: string
) {
  if (seriesUnit === displayUnit) return measurement;

  // Find a conversion function
  const conv = (x: string | number | undefined) =>
    ifDefined(x, (x) => {
      if (typeof units.convert[seriesUnit]?.to[displayUnit] === "function")
        return units.convert[seriesUnit].to[displayUnit](Number(x));
      else return Number(x);
    });

  return {
    ...measurement,
    unit: displayUnit,
    actual: conv(measurement.actual)!,
    deviation: conv(measurement.deviation),
    error: conv(measurement.error),
    nominal: conv(measurement.nominal),
    upperWarnLimit: conv(measurement.upperWarnLimit),
    lowerWarnLimit: conv(measurement.lowerWarnLimit),
    upperLimit: conv(measurement.upperLimit),
    lowerLimit: conv(measurement.lowerLimit),
  };
}

function convertLimits(
  limitValues: TimeSeriesLimit,
  converter: (val: number) => number
): TimeSeriesLimit {
  const lowerDisplayLimit = limitValues.lowerDisplayLimit
    ? converter(limitValues.lowerDisplayLimit)
    : limitValues.lowerDisplayLimit;
  const upperDisplayLimit = limitValues.upperDisplayLimit
    ? converter(limitValues.upperDisplayLimit)
    : limitValues.upperDisplayLimit;
  const lowerOperatingLimit = limitValues.lowerOperatingLimit
    ? converter(limitValues.lowerOperatingLimit)
    : limitValues.lowerOperatingLimit;
  const upperOperatingLimit = limitValues.upperOperatingLimit
    ? converter(limitValues.upperOperatingLimit)
    : limitValues.upperOperatingLimit;
  const lowerWarningLimit = limitValues.lowerWarningLimit
    ? converter(limitValues.lowerWarningLimit)
    : limitValues.lowerWarningLimit;
  const upperWarningLimit = limitValues.upperWarningLimit
    ? converter(limitValues.upperWarningLimit)
    : limitValues.upperWarningLimit;

  return {
    lowerDisplayLimit: lowerDisplayLimit,
    upperDisplayLimit: upperDisplayLimit,
    lowerOperatingLimit: lowerOperatingLimit,
    upperOperatingLimit: upperOperatingLimit,
    lowerWarningLimit: lowerWarningLimit,
    upperWarningLimit: upperWarningLimit,
    timeSeriesId: limitValues.timeSeriesId,
    created: limitValues.created,
  };
}
