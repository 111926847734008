/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { getType } from "typesafe-actions";
import {
  getStaticData,
  liveRefreshWatcher,
  calculateJobPerformanceOnMetadataChange,
} from "./sagas";
import { rootActions } from "../";
import {
  all,
  race,
  takeLatest,
  take,
  call,
  fork,
  takeEvery,
} from "typed-redux-saga";
import {
  fetchFilesDetailsForFileInfoIds,
  downloadFileForSelectedFileId,
} from "../global/sagas";

export function* jobPerformanceRootSaga() {
  yield* fork(jobPerfRefreshLoopSaga);
  // Initial work
  yield* all([
    yield* race({
      jobSummaryPopupFilesTabClickedWatcher: takeLatest(
        getType(rootActions.global.jobSummaryPopupFilesTabClicked),
        fetchFilesDetailsForFileInfoIds
      ),
      downloadFileWatcher: takeLatest(
        getType(rootActions.global.jobSummaryPopupDownloadFileButtonClicked),
        downloadFileForSelectedFileId
      ),
      cancel: take(getType(rootActions.session.startEndUpdated)),
    }),

    yield* takeLatest(
      getType(rootActions.global.jobSummaryPopupFilesTabClicked),
      fetchFilesDetailsForFileInfoIds
    ),
    yield* takeLatest(
      getType(rootActions.global.jobSummaryPopupDownloadFileButtonClicked),
      downloadFileForSelectedFileId
    ),
  ]);
}

function* jobPerfRefreshLoopSaga() {
  while (true) {
    yield* call(getStaticData);
    yield* race({
      groupJobByChanged: takeEvery(
        getType(rootActions.jobPerformance.groupJobByChanged),
        calculateJobPerformanceOnMetadataChange
      ),
      liveRefreshWatcher: call(liveRefreshWatcher),
      cancel: take([getType(rootActions.session.startEndUpdated)]),
    });
  }
}
