/*
 * © 2024 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { call, getContext, put } from "typed-redux-saga";
import { rootActions } from "..";
import { CMSClientType } from "@/cms-api";

export function* fetchConnectionDetails() {
  try {
    const client = yield* getContext<CMSClientType>("client");
    const response = yield* call(() => client.myConnectors.get().promise);
    yield* put(rootActions.manageConnectors.setMyConnectorsDetails(response));
  } catch (e) {
    console.log(e);
  }
}
