/*
 * © 2024 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { deprecated, ActionType } from "typesafe-actions";
import { ManageConnectorsPresentation } from "../state";
const { createStandardAction } = deprecated;

export const actions = {
  setMyConnectorsDetails: createStandardAction(
    "[manage connectors] my connectors details updated"
  )<ManageConnectorsPresentation[]>(),
};
export type Actions = ActionType<typeof actions>;
